import React, {useContext, useState} from 'react';
import NavBar from "../components/Landing/NavBar";
import {Box, CircularProgress, Container, Grid, InputAdornment, Paper, TextField,} from "@material-ui/core";
import {TextInputMaterial} from "../components/widgets/TextInputMaterial";
import useInputMaterial from "../hooks/useInputMaterial";
import Button from "@material-ui/core/Button";
import 'react-phone-input-2/lib/style.css'
import CompanyAutocomplete from "../components/dadata/company_autocomplete";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Alert} from "@material-ui/lab";
import $dadata from "../http/dadata_index";
import InputMask from "react-input-mask";
import {useFetching} from "../hooks/useFetching";
import {green} from "@material-ui/core/colors";
import { makeStyles } from '@material-ui/core/styles';
import {CompanyService} from "../service/CompanyService";
import {CreateCompanyDto} from "../models/dto/create-company.dto";
import {NewCompanyResponse} from "../models/response/new-company.Response";
import {COMPANY, USERS} from "../utils/consts";
import {Context} from "../index";
import {useHistory} from "react-router-dom";
import CityAutocomplete from "../components/dadata/city_autocomplete";
import { SuggestionCity} from "../models/dadata/city";
import {Suggestion} from "../models/dadata/party";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));


const Registration = () => {
    const name=useInputMaterial('');
    const classes = useStyles();
    const {store} = useContext(Context);
    const history=useHistory();

    const email=useInputMaterial('');
    const city=useInputMaterial('');
    const [company,setCompany]=useState({} as Suggestion);
    const [phone,setPhone]=useState('')
    const [success, setSuccess] = React.useState(false);
    const [data,setData]=useState<CreateCompanyDto>({name:'-1'} as CreateCompanyDto)
    document.title = "Регистрация"

    const [registrationAction,isLoading,error] = useFetching(async ()=>{
        let login=parseInt(phone.replace(/[^\d]/g, '')).toString().substring(1);
        const res=await CompanyService.createCompany({
            tariff: 1,
            name:name.value,
            phone:login,
            inn:company.data.inn,
            cityName:city.value,
            email:email.value
        });
        setSuccess(true)
        localStorage.setItem('token', res.data.assetsToken);
        store.setAuth(true);
        store.setUser(res.data.user);
        history.push(COMPANY);
    })

    const selectCompany = (c : Suggestion) => {
      setCompany(c);
    }
    const registration = async () => {
        let login=parseInt(phone.replace(/[^\d]/g, '')).toString().substring(1);
        if(name.value==''){
            alert('Укажите имя')
            return;
        }
        if(email.value==''){
            alert('Укажите email')
            return;
        }
        if(city.value==''){
            alert('Укажите город')
            return;
        }
        if(login==''){
            alert('Укажите телефон')
            return;
        }
        if (company.value==undefined){
            alert('Укажите компанию')
            return;
        }
        setData({
            tariff: 1,
            name:name.value,
            phone:login,
            inn:company.data.inn,
            cityName:city.value,
            email:email.value
        })
        await registrationAction();

    }


    return (
        <div>
            <NavBar/>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '90vh' }}
            >
                <Grid container justify = "center" >
                    <Container maxWidth="sm" fixed >
                        <Paper elevation={3} >
                            <Box p={3}>
                                {
                                    (error!='')?<Alert severity="error">{error}</Alert>:''
                                }
                                <TextInputMaterial {...name} label={'Ваше имя'}/>
                                <InputMask
                                    mask="+7 (999) 999-9999"
                                    value={phone}
                                    disabled={false}
                                    onChange={e => setPhone(e.target.value)}
                                >
                                    {() => <TextField variant={"outlined"} className={"mt-3"} fullWidth={true} label={'Телефон'}/>}
                                </InputMask>
                                <CityAutocomplete setCity={(e:SuggestionCity)=> city.setValue(e.data.city)}/>
                                <CompanyAutocomplete setInn={selectCompany} />
                                <TextInputMaterial {...email} label={'Ваш email'}/>
                                <div className={classes.wrapper}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isLoading}
                                        onClick={registration}
                                        fullWidth={true}
                                    >
                                        Регистрация
                                    </Button>
                                    {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                                <p>Продолжая вы выражаете согласие с <a href="docs/СОГЛАШЕНИЕ ОБ ИСПОЛЬЗОВАНИИ САЙТА.pdf" target={"_blank"}>соглашением об использовании сайта</a> и <a href="privacy.pdf"  target={"_blank"}> политикой в отношении обработки персональных данных</a></p>

                            </Box>
                        </Paper>
                    </Container>
                </Grid>
            </Grid>
        </div>
    );
};

export default Registration;