import {AxiosResponse} from "axios";
import $api from "../http";
import {IReport} from "../models/IReport";

export default class ReportService{
    static async fetchReports(): Promise<AxiosResponse<IReport[]>>{
        return $api.get('/report/getReports')
    }
    static async deleteReport(id:number):Promise<AxiosResponse<any>>{
        return $api.delete('/report/'+id,);
    }
    static async editReport(data:IReport):Promise<AxiosResponse<IReport>>{
        return $api.post('/report/save',data)
    }
    static async getReportsTest(limit:number,page:number):Promise<AxiosResponse<IReport[]>>{
        return $api.get(`/report/getReportsList?limit=${limit}&page=${page}`)
    }
    static async printReport(filters:string):Promise<AxiosResponse<String>>{
        return $api.get(`/report/printReport/${filters}/2021-12-01/2020-12-30`)
    }
}