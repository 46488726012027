import {makeAutoObservable} from "mobx";
import {IRequest} from "../models/IRequest";
import {IErrors} from "../models/IErrors";
import RequestService from "../service/RequestService";
import {CreateRequestDto} from "../models/dto/create-request.dto";

export default class Request_store{
    isLoading = false;
    requestList=[] as IRequest[]
    errors=[] as IErrors[]
    constructor() {
        makeAutoObservable(this)
    }
    setLoading(bool: boolean) {
        this.isLoading = bool;
    }
    setRequestList(req:IRequest[]){
        this.requestList=req;
    }
    setErrors(err:IErrors[]){
        this.errors=err;
    }
    addRequest(req:IRequest){
        this.requestList.push({...req,
            phone:req.customerData?.phone,
            customerName:req.customerData?.name,
        });
    }

    async fetchRequest(){
        try{
            this.setLoading(true);
            const res=await RequestService.fetchRequest();
            this.setRequestList(res.data);
        }catch (e) {
            throw new Error(e);
        }finally {
            this.setLoading(false)
        }
    }
    async createRequest(data:CreateRequestDto){
        try {
            this.setLoading(true);
            const res=await RequestService.createRequest(data)
            this.addRequest(res.data)
            console.log(res.data)
        }catch (e){
            console.log(JSON.stringify(e))
            throw new Error(e);
        }finally {
            this.setLoading(false)
        }
    }
}