import React, {useContext, useState} from 'react';
import NavBar from "../components/Landing/NavBar";
import MainHeader from "../components/Landing/MainHeader";
import PriceCard from "../components/Landing/PriceCard";
import {useHistory, useLocation} from "react-router-dom";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {IPriceCard} from "../models/IPriceCard";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

const LandingMain = () => {
    const location = useLocation();
    const history=useHistory();
    const {store} = useContext(Context);
    document.title = "Мобильный аршин"

    const [show,setShow]=useState(false)


    return (
        <div id="layoutDefault">
            <div id={"layoutDefault_content"}>
                <main>
                    <NavBar/>
                    <MainHeader/>
                    <section className="bg-light pb-10 pt-1">
                        <div className="container px-5">
                            <div className="device-laptop text-gray-200 mt-n10" data-aos="fade-up">
                                <svg className="device-container" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="83.911 298.53 426.962 243.838">
                                    <path
                                        d="M474.843 516.208V309.886c0-6.418-4.938-11.355-11.354-11.355H131.791c-6.417 0-11.354 4.938-11.354 11.355v206.816H83.911v13.326c4.938 7.896 31.098 12.34 40.969 12.34h345.024c10.366 0 36.526-4.936 40.969-12.34v-13.326h-36.03v-.494zM134.26 313.341h326.762v203.361H134.26V313.341z"></path>
                                </svg>
                                <img className="device-screenshot" src="assets/img/lang.png"/>
                            </div>
                        </div>
                    </section>

                    <section className="bg-light py-10">
                        <div className="container px-5">
                            <div className="row gx-5 justify-content-center">
                                <div className="col-lg-6">
                                    <div className="text-center">
                                        <div className="text-xs text-uppercase-expanded text-primary mb-2">Преимущества</div>
                                        <h2 className="mb-5">CRM для метрологической службы</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row gx-5">
                                <div className="col-lg-4 col-md-6 mb-5" data-aos="fade-up">
                                    <a className="card text-center text-decoration-none h-100 lift" href="#!">
                                        <div className="card-body py-5">
                                            <div className="icon-stack icon-stack-lg bg-green-soft text-green mb-4"><i
                                                data-feather="layers"></i></div>
                                            <h5>Форматы отчетов</h5>
                                            <p className="card-text small">Формирование отчетов в форматах: <br/>Xlsx(excel) и xml (для аршина).</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-5" data-aos="fade-up" data-aos-delay="100">
                                    <a className="card text-center text-decoration-none h-100 lift" href="#!">
                                        <div className="card-body py-5">
                                            <div className="icon-stack icon-stack-lg bg-red-soft text-red mb-4"><i
                                                data-feather="tool"></i></div>
                                            <h5>Контроль</h5>
                                            <p className="card-text small">Вся информация о работе метролога в одном месте.</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-5" data-aos="fade-up" data-aos-delay="150">
                                    <a className="card text-center text-decoration-none h-100 lift" href="#!">
                                        <div className="card-body py-5">
                                            <div className="icon-stack icon-stack-lg bg-yellow-soft text-yellow mb-4"><i
                                                data-feather="layout"></i></div>
                                            <h5>Удобство</h5>
                                            <p className="card-text small">Автоматическое формирование протокола поверки.</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-5 mb-lg-0" data-aos="fade-up">
                                    <a className="card text-center text-decoration-none h-100 lift" href="#!">
                                        <div className="card-body py-5">
                                            <div className="icon-stack icon-stack-lg bg-purple-soft text-purple mb-4"><i
                                                data-feather="book"></i></div>
                                            <h5>База</h5>
                                            <p className="card-text small">Единая база измерительных приборов в приложении.</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-5 mb-md-0" data-aos="fade-up" data-aos-delay="100">
                                    <a className="card text-center text-decoration-none h-100 lift" href="#!">
                                        <div className="card-body py-5">
                                            <div className="icon-stack icon-stack-lg bg-blue-soft text-blue mb-4"><i
                                                data-feather="code"></i></div>
                                            <h5>Поиск</h5>
                                            <p className="card-text small">Быстрый поиск по единой базе данных.</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="150">
                                    <a className="card text-center text-decoration-none h-100 lift" href="#!">
                                        <div className="card-body py-5">
                                            <div className="icon-stack icon-stack-lg bg-orange-soft text-orange mb-4"><i
                                                data-feather="package"></i></div>
                                            <h5>Стоимость</h5>
                                            <p className="card-text small">Два просты тарифа для компаний с любым штатом.</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="bg-light pt-10">
                        <div className="container px-5">
                            <div className="text-center mb-5">
                                <h2>Наши цены</h2>
                                <p className="lead">Три простых тарифа</p>
                            </div>
                            <div className="row gx-5 z-1">
                                <PriceCard  cost={'1000'} name={'Старт'} options={[
                                    {name:'Неограниченное число заявок и поверок',active:true} as IPriceCard,
                                    {name:'Доступ к базе приборов учета',active:true} as IPriceCard,
                                    {name:'Ведение клиентской базы',active:true} as IPriceCard,
                                    {name:'Аналитика заявок',active:true} as IPriceCard,
                                    {name:'Привязка сотрудников к разным городам',active:true} as IPriceCard,
                                    {name:'Отправка протокола поверки заказчику',active:true} as IPriceCard,
                                    {name:'Оповещение сотрудников о новых заявках',active:true} as IPriceCard,
                                    {name:'Отправка протокола поверки заказчику',active:true} as IPriceCard,
                                    {name:'Неограниченное число пользователей',active:false} as IPriceCard,


                                ]} term={'За пользователя в месяц'}/>
                                <PriceCard  cost={'8000'} name={'Про'} options={[
                                    {name:'Неограниченное число заявок и поверок',active:true} as IPriceCard,
                                    {name:'Доступ к базе приборов учета',active:true} as IPriceCard,
                                    {name:'Ведение клиентской базы',active:true} as IPriceCard,
                                    {name:'Аналитика заявок',active:true} as IPriceCard,
                                    {name:'Привязка сотрудников к разным городам',active:true} as IPriceCard,
                                    {name:'Отправка протокола поверки заказчику',active:true} as IPriceCard,
                                    {name:'Оповещение сотрудников о новых заявках',active:true} as IPriceCard,
                                    {name:'Отправка протокола поверки заказчику',active:true} as IPriceCard,
                                    {name:'Неограниченное число пользователей',active:true} as IPriceCard,


                                ]} term={'В месяц'}/>
                            </div>
                        </div>
                        <div className="svg-border-rounded text-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none"
                                 fill="currentColor">
                                <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
                            </svg>
                        </div>
                    </section>
                    <section className={"bg-dark pb-10 pt-15"}>
                        <div className={" container"}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className=" footer-brand">Мобильный аршин</div>
                                    <div className="mb-3">Простые решения сложных проблем</div>
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            <img src={'img/Maestro верт.png'} className={"p-2"} alt={''} style={{width:75}}/>
                                            <img src={'img/MC верт.png'} alt={''} className={"p-2"} style={{width:75}}/>
                                            <img src={'img/Mir_rus.PNG'} alt={''} className={"p-2"} style={{width:75}}/>
                                            <img src={'img/verified.png'} alt={''} className={"p-2"} style={{width:75}}/>
                                            <img src={'img/visa_pos_.png'} alt={''} className={"p-2"} style={{width:75}}/>
                                            <img src={'img/MIRaccept.PNG'} alt={''} className={"p-2"} style={{width:75}}/>
                                            <img src={'img/visa_pos_electron_.png'} alt={''} className={"p-2"} style={{width:75}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-5"/>
                            <div className="row align-items-center">
                                <div className="col-md-6 small">&copy; mobile-arshin.ru 2021</div>
                                <div className="col-md-6 text-md-right small">
                                    <a href="docs/СОГЛАШЕНИЕ ОБ ИСПОЛЬЗОВАНИИ САЙТА.pdf" target={"_blank"}>СОГЛАШЕНИЕ ОБ ИСПОЛЬЗОВАНИИ САЙТА</a>
                                    &middot;
                                    <a href="privacy.pdf"  target={"_blank"}> Политика в отношении обработки персональных данных</a>
                                </div>
                            </div>
                        </div>

                    </section>

                </main>
            </div>
        </div>
        );

};

export default observer(LandingMain);