import React, {useContext, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import Main from "../components/Main";
import Header from "../components/Header";
import {Checkbox, createStyles, LinearProgress, Paper, TextField} from "@material-ui/core";
import {lighten, makeStyles, Theme} from "@material-ui/core/styles";
import DateButton from "../components/DateButton";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import useScroll from "../hooks/useScroll";
import TableReport from "../components/widgets/TableReport";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {IReport} from "../models/IReport";
import ReportService from "../service/ReportService";
import ButtonOptionSelect from "../components/widgets/ButtonOptionSelect";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '80vh',
        },
        paper:{
            margin: theme.spacing(2),
            height: '100%',
            padding: theme.spacing(2)
        },
        textInput:{
            marginTop: theme.spacing(2),
        },
        container: {
            marginTop: theme.spacing(2),
            maxHeight: '50vh',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);
const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
    }),
);

interface EnhancedTableToolbarProps {
    numSelected: number;
}
interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
}
interface HeadCell {
    disablePadding: boolean;
    label: string;
    numeric: boolean;
}
const headCells: HeadCell[] = [
    {  numeric: false, disablePadding: true, label: 'Город' },
    {  numeric: false, disablePadding: false, label: 'ФИО поверителя' },
    {  numeric: false, disablePadding: false, label: 'ФИО в Аршин' },
    {  numeric: false, disablePadding: false, label: 'Номер ФИФ' },
    {  numeric: false, disablePadding: false, label: 'Модификация СИ' },
    {  numeric: false, disablePadding: false, label: 'Номер счетчика' },
    {  numeric: false, disablePadding: false, label: 'Дата поверки' },
    {  numeric: false, disablePadding: false, label: 'Действительна до' },
    {  numeric: false, disablePadding: false, label: 'Эталон' },
    {  numeric: false, disablePadding: false, label: 'Температура' },
    {  numeric: false, disablePadding: false, label: 'Давление' },
    {  numeric: false, disablePadding: false, label: 'Влажность' },
];
function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, onSelectAllClick, numSelected, rowCount,  } = props;


    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell,index) => (
                    <TableCell
                        key={index}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ?? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} выбранно
                </Typography>
            )}
            {numSelected > 0 ?? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            )
            }
        </Toolbar>
    );
};


const ReportsTest = () => {
    const classes = useStyles();
    const [loading,setLoading]=useState(false)
    const parentRef=useRef(null)
    const childRef=useRef<any>(null)
    const [reportList,setReport]=useState([] as IReport[]);
    const [selected, setSelected] = React.useState<number[]>([]);
    const [stop,setStop]=useState(false)


    const [page, setPage] = useState(0);
    const limit = 20;

    document.title = "Отчеты"
    const {reports}=useContext(Context);

    function fetchTodos(page:number, limit:number) {
        if(!stop){
            setLoading(true)
            ReportService.getReportsTest(limit,page).then((value)=>{
                if (value.data.length==0){
                    setStop(true)
                }
                setReport(prev => [...prev, ...value.data]);
                setPage(prev => prev + 1)

            }).catch(e=>{
                alert(e.messageerror)

            }).finally(()=>{
                setLoading(false)
            })
        }

    }

    useScroll(parentRef, childRef, () => fetchTodos(page, limit));
    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = reportList.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);

    };




    return (
        <Main>
            <Header
                buttons={''}
                isLoading={reports.isLoading}
                namePage={'Отчеты'}
            />
            <div className={classes.root}>
                <Paper className={classes.paper} >
                    <DateButton onSelect={()=>{}}/>
                    <TextField className={classes.textInput} fullWidth label="Поиск" variant="outlined" />
                    <EnhancedTableToolbar numSelected={selected.length} />

                    <TableContainer className={classes.container} ref={parentRef}>
                        <LinearProgress hidden={!loading}/>

                        <Table stickyHeader aria-label="sticky table">
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={reportList.length}
                            />
                            <TableBody>
                                {reportList.map((row,index)=>{
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                {row.number}
                                            </TableCell>
                                            <TableCell align="right">{row.id}</TableCell>
                                            <TableCell align="right">{row.number}</TableCell>
                                            <TableCell align="right">{row.number}</TableCell>
                                            <TableCell align="right">{row.number}</TableCell>
                                        </TableRow>
                                    )
                                    })
                                }



                                <div ref={childRef} style={{height:10}}>

                                </div>
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Paper>
            </div>
        </Main>
    );
};

export default observer(ReportsTest);