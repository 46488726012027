import React, {useContext} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {Context} from "../../index";
import {useHistory} from "react-router-dom";
import {COMPANY, LOGIN_ROUTE, REGISTRATION} from "../../utils/consts";
import {Box} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        toolBar:{
            backgroundColor: "#ffffff"
        }
    }),
);
const NavBar = () => {
    const classes = useStyles();
    const {store}=useContext(Context);
    const history=useHistory();

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar className={classes.toolBar}>
                    <Box className={classes.title} >
                        <img src='../assets/img/a_50.png'  alt={'title'}  />
                    </Box>
                    <div>
                        {
                            (!store.isAuth)?
                                <div>
                                    <Button variant="outlined" color="primary" onClick={()=>history.push(LOGIN_ROUTE)}>
                                        Вход
                                    </Button>
                                    <Button variant="contained" className={'ml-3'} color="primary" onClick={()=>history.push(REGISTRATION)}>
                                        Регистрация
                                    </Button>
                                </div>
                                :
                                <Button variant="outlined" color="primary" onClick={()=>history.push(COMPANY)}>
                                    Личный кабинет
                                </Button>
                        }
                    </div>

                </Toolbar>
            </AppBar>
        </div>
    );
};

export default NavBar;