import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Store from "./store/store";
import Reports_store from "./store/reports_store";
import UsersArshinStore from "./store/users_arshin_store";
import Company_store from "./store/company_store";
import Request_store from "./store/request_store";
import Customer_store from "./store/customer_store";

interface State {
    store: Store,
    reports:Reports_store,
    usersArshin:UsersArshinStore,
    company:Company_store,
    requests:Request_store,
    customers:Customer_store
}
export const store = new Store();
export const reports = new Reports_store();
export const usersArshin= new UsersArshinStore()
export const company=new Company_store();
export const requests=new Request_store();
export const customers=new Customer_store()

export const Context = createContext<State>({
    store,
    reports,
    usersArshin,
    company,
    requests,
    customers
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

