import {AxiosResponse} from "axios";
import $api from "../http";
import {CreateCompanyDto} from "../models/dto/create-company.dto";
import {NewCompanyResponse} from "../models/response/new-company.Response";
import {CompanyData, Order} from "../models/response/CompanyData";
import {IPaymentCheck} from "../models/IPaymentCheck";

export  class CompanyService{
    static async createCompany(data:CreateCompanyDto):Promise<AxiosResponse<NewCompanyResponse>>{
        return $api.post('/auth/CheckIn',data)
    }
    static async getCompanyInfo():Promise<AxiosResponse<CompanyData>>{
        return $api.get('/company/getMyCompanyInfo')
    }
    static async createOrder(sum:number,type:number):Promise<AxiosResponse<Order>>{
        return $api.post('/order/create',{"cost":sum,'type':type});
    }
    static async checkPayment(token:string):Promise<AxiosResponse<IPaymentCheck>>{
        return $api.get('/order/checkPayment/'+token)
    }
    static async changeTariff(tariff:number):Promise<AxiosResponse<CompanyData>>{
        return $api.post('/company/startChangeTariff',{tariff:tariff})
    }
    static async createInvoice(id:number):Promise<AxiosResponse<string>>{
        return $api.get('/order/generateInvoice/'+id)
    }
}