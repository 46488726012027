import $dadata from "../http/dadata_index";
import {City} from "../models/dadata/city";
import {AxiosResponse} from "axios";
import {Address} from "../models/dadata/address";

export default class DadataService{
    static async fetchCity(city:string):Promise<AxiosResponse<City>>{
        return $dadata.post('/address',{ "query": city,"from_bound": { "value": "city" }, "to_bound": { "value": "city" }   })
    }
    static async fetchAddress(query:string):Promise<AxiosResponse<Address>>{
        return $dadata.post('/address',{'query':query});
    }
}