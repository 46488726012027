import React, {useContext, useEffect, useState} from 'react';
import Main from "../components/Main";
import Header from "../components/Header";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import MUIDataTable, {MUIDataTableColumnDef, MUIDataTableOptions} from "mui-datatables";
import moment from "moment";
import {useSnackbar} from "notistack";
import {Button} from "react-bootstrap";
import AddRequest from "../components/modals/AddRequest";
import {CreateRequestDto} from "../models/dto/create-request.dto";
import {Chip, createStyles, MuiThemeProvider} from "@material-ui/core";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckIcon from '@material-ui/icons/Check';
import TableContainer from "@material-ui/core/TableContainer";
import {createMuiTheme, makeStyles, Theme} from "@material-ui/core/styles";
const SPACED_DATE_FORMAT = "YYYY-MM-DD";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '80vh',
        },
        paper:{
            margin: theme.spacing(2),
            height: '100%',
            padding: theme.spacing(2)
        },
        textInput:{
            marginTop: theme.spacing(2),
        },
        container: {
            margin: theme.spacing(4),
            maxHeight: '50vh',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);

const columns:MUIDataTableColumnDef []= [
    {
        name: 'city',
        label: 'Город',
        options:{
            setCellHeaderProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    position: "sticky",
                    left: 0,
                    background: "white",
                    zIndex: 101
                }
            })
        }
    },
    {
        name: 'customerName',
        label: 'Заказчик',
        //description: 'Реальный создатель отчета',
        //  minWidth:200,
        //  type:'string',
        //  editable: false,
    },
    {
        name: 'phone',
        label: 'Телефон',
        //  type: 'string',
        //  minWidth:200,
        //    editable: false,

    },
    {
        name: 'address',
        label: 'Адрес',

        //  description: 'Номер ФИФ',
        //  type: 'string',
        //    editable: false,
    },
    {
        name: 'type',
        label: 'Тип',
        //  type: 'string',
        //   minWidth:200,
        //    editable: false,
    },
    {
        name: 'createdAt',
        label: 'Дата Создания',
        options:{
            customBodyRender: value =>
                moment(new Date(value)).format(SPACED_DATE_FORMAT)
        }
        //    type: 'date',
        ///     minWidth:200,
        //     editable: false,
    },
    {
        name: 'status',
        label: 'Статус',
        options:{
            customBodyRender: value =>{
                if (value=='Новая'){
                    return <Chip variant="outlined" color="primary" icon={<AccessTimeIcon />} label={value}/>
                }else {
                    return <Chip variant="outlined" color={'secondary'} icon={<CheckIcon />}  label={value}/>
                }
            }
        }
        // type: 'string',
        //   minWidth:200,
        //   editable: false,
    },

];



const Requests = () => {
    const classes = useStyles();

    const {requests}=useContext(Context)
    const { enqueueSnackbar } = useSnackbar();
    const [showNew,setShowNew]=useState(false);

    const createRequest = (r:CreateRequestDto) => {
        setShowNew(false);
        requests.createRequest(r).then().catch(e=>{
            enqueueSnackbar(e.message)
        })
    }


    useEffect(()=>{
        requests.fetchRequest().then().catch(e=>{
            alert(e.message)
        })
    },[])

    const options:MUIDataTableOptions = {
        filter: true,
        filterType: "dropdown",
        selectableRows: "none",
        draggableColumns: {
            enabled: true
        },
        tableBodyHeight:'60vh',
        tableBodyMaxHeight:'60vh',
        onFilterChange:(changedColumn,filterList,type,changedColumnIndex,displayData)=>{
            console.log(JSON.stringify(displayData));
            /*
            const idArray=displayData.map(item=>{
                return reports.reports[item.dataIndex].id;
            });
            setFilters(JSON.stringify(idArray));

             */
        },
        onSearchChange:(searchText)=>{

        },
        onRowClick:(rowData: string[], rowMeta: { dataIndex: number; rowIndex: number })=>{
           // setSelectReport(reports.reports[rowMeta.rowIndex]);
         //   setShowEdit(true)
        },
        search:false,
        print:false,
        download:false,
        textLabels: {
            body: {
                noMatch: "Ничего не найдено",
                toolTip: "Поиск",
                columnHeaderTooltip: column => `Поиск по ${column.label}`
            },
            pagination: {
                next: "Следующая страница",
                previous: "Прошлая страница",
                rowsPerPage: "Строк на странице:",
                displayRows: "of",
            },
            toolbar: {
                search: "Поиск",
                downloadCsv: "Скачать отчет",
                print: "Print",
                viewColumns: "Столбцы",
                filterTable: "Фильтры",
            },
            filter: {
                all: "Все",
                title: "Фильтры",
                reset: "Сборсть фильтры",
            },
            viewColumns: {
                title: "Список столбцов",
                titleAria: "Скрыть/показать столбец",
            },
            selectedRows: {
                text: "Строк выбранно",
                delete: "Удалить",
                deleteAria: "Удалить выделенные строки",
            },
        },
    };


    document.title = "Заявки"


    return (
        <Main>
            <Header
                buttons={<Button onClick={()=>setShowNew(true)}  className="btn btn-sm btn-light text-primary active mr-2">Новая заявка</Button>}
                isLoading={requests.isLoading}
                namePage={'Заявки'}
            />
            <div className="container">
                <MUIDataTable
                    title={"Список заявок"}
                    data={requests.requestList.map(item=>{return {...item,
                        phone:item.customerData?.phone,
                        customerName:item.customerData?.name,
                    }})}
                    columns={columns}
                    options={options}
                />

                <AddRequest show={showNew} handleClose={()=>setShowNew(false)} createRequest={createRequest}/>
            </div>

        </Main>
    );
};

export default observer(Requests);