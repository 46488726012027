export const LOGIN_ROUTE = '/login'
export const FORM='/form'
export const USERS='/users'
export const REPORTS='/reports'
export const USERS_ARSHIN='/users_arshin'
export const COMPANY='/company'
export const REQUESTS='/requests'
export const CUSTOMERS='/customers'
export const REQUEST_TEST='/test'
export const CHECK_PAYMENT='/check_payment'

export const SITE='/'
export const REGISTRATION='/registration'

export const REPORTS_TEST='/reportTest'