import React, {FC} from 'react';
import {IPriceCard} from "../../models/IPriceCard";
import {Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {REGISTRATION} from "../../utils/consts";

interface PriceCardProps{
    name:string
    cost:string
    term:string
    options:IPriceCard[]
}
const PriceCard:FC<PriceCardProps> = (props) => {
    const history=useHistory();
    return (
        <div className="col-lg-6 mb-5 mb-lg-n10" data-aos="fade-up" data-aos-delay="100">
            <div className="card pricing h-100">
                <div className="card-body p-5">
                    <div className="text-center">
                        <div
                            className="badge bg-light text-dark rounded-pill badge-marketing badge-sm">{props.name}
                        </div>
                        <div className="pricing-price">
                            {props.cost}
                            <sup>₽</sup>
                            <p className="pricing-price-period">{props.term}</p>
                        </div>
                    </div>
                    <ul className="fa-ul pricing-list">
                        {
                            props.options.map(item=>{
                                return (
                                    <li className="pricing-list-item">
                                        <span className="fa-li">
                                            <i className={(item.active)?"far fa-check-circle text-teal":"far fa-circle text-gray-200"}></i>
                                        </span>
                                        <span className="text-dark">{item.name}</span>
                                    </li>
                                );
                            })
                        }
                    </ul>
                    <Button fullWidth={true}  variant="contained" color="primary" onClick={()=>history.push(REGISTRATION)}>
                        Купить
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PriceCard;