import {
    CHECK_PAYMENT,
    COMPANY, CUSTOMERS,
    FORM,
    LOGIN_ROUTE, REGISTRATION, REPORTS, REPORTS_TEST, REQUEST_TEST, REQUESTS, SITE, USERS, USERS_ARSHIN,
} from "./utils/consts";
import Login from "./pages/Login";
import Form from "./pages/Form";
import Users from "./pages/Users";
import Reports from "./pages/Reports";
import UsersArshin from "./pages/UsersArshin";
import LandingMain from "./pages/Landing_main";
import Registration from "./pages/Registration";
import Company from "./pages/Company";
import Requests from "./pages/Requests";
import Cusromers from "./pages/Cusromers";
import RequestTest from "./pages/RequestTest";
import ReportsTest from "./pages/ReportsTest";
import PaymentCheck from "./pages/paymentCheck";

export const authRoutes = [
    {
        path: USERS,
        Component:Users
    },

    {
        path:REPORTS,
        Component:Reports
    },
    {
        path:USERS_ARSHIN,
        Component:UsersArshin
    },
    {
        path: COMPANY,
        Component:Company
    },
    {
        path: REQUESTS,
        Component:Requests
    },
    {
        path: CUSTOMERS,
        Component:Cusromers
    },
    {
        path: REQUEST_TEST,
        Component:RequestTest
    },
    {
        path: REPORTS_TEST,
        Component:ReportsTest
    }
]
export const publicRoutes = [

    {
        path: LOGIN_ROUTE,
        Component: Login
    },
    {
        path: FORM,
        Component:Form
    },
    {
        path:SITE,
        Component:LandingMain
    },
    {
        path:REGISTRATION,
        Component:Registration
    },
    {
        path:CHECK_PAYMENT,
        Component:PaymentCheck
    }

]
