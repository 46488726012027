import {makeAutoObservable} from "mobx";
import {AuthService} from "../service/AuthService";
import {IUser} from "../models/IUser";
import {UsersService} from "../service/UsersService";
import {IWorker, Reference} from "../models/IWorkers";
import {ICounterType} from "../models/ICounterType";
import {CountersTypeService} from "../service/CountersTypeService";
import parsePhoneNumberFromString from "libphonenumber-js";



export default class Store {
    user = {} as IUser;
    isAdmin=false;
    usersList=[] as IWorker[]
    countersType=[]as ICounterType[]
    fifsList=[]as string[]
    methodCT=[]as string[];
    nameCT=[]as string[]
    modificationsCT= [] as string[]
    manufacturerCT=[]as string[]
    isAuth = false;
    isLoading = false;

    setIsAdmin(isAdmin:boolean){
        this.isAdmin=isAdmin;
    }


    constructor() {
        makeAutoObservable(this);
    }
    setAuth(bool: boolean) {
        this.isAuth = bool;
    }
    setUser(user: IUser) {
        this.user = user;
    }
    setLoading(bool: boolean) {
        this.isLoading = bool;
    }
    setUsersList(users:IWorker[]){
        this.usersList=users.map(item=>{
            return {
                ...item,
                phoneData:normalPhone(item.phone??''),
                referenceG:methodRender(item.reference,'ГОСТ 8.156-83'),
                referenceM:methodRender(item.reference,'МИ 1592-2015'),
                clickEvent:()=>{
                    alert('asd')
                }
            }
        })
    }
    pushUser(user:IWorker){
        this.usersList.push(user);
    }
    setCountersType(types:ICounterType[]){
        this.countersType=types
    }
    setFifsList(fifs:string[]){
        this.fifsList=fifs
    }
    setMethodCT(methods:string[]){
        this.methodCT=methods;
    }
    setNameCT(names:string[]){
        this.nameCT=names;
    }
    setModificationsCT(modifications:string[]){
        this.modificationsCT=modifications;
    }
    setManufacturerCT(manufacturers:string[]){
        this.manufacturerCT=manufacturers;
    }
    pushTypeCounter(counter:ICounterType){
        this.countersType.push(counter);
    }
    deleteTypeCounterFromList(id:number){
        this.setCountersType(this.countersType.filter(item=>item.id!==id))
    }
    updateUser(user:IWorker){
        this.setUsersList(this.usersList.map(item=>(item.id===user.id)?user:item))
    }
    updateCounter(counter:ICounterType){
        this.setCountersType(this.countersType.map(item=>(item.id===counter.id)?counter:item))
    }

    async login(email: string, password: string):Promise<boolean> {
        this.setLoading(true);
        try {
            const response = await AuthService.login(email, password);
            localStorage.setItem('token', response.data.assetsToken);
            this.setAuth(true);
            this.setUser(response.data.user);
            return true;
        } catch (e) {
            console.log(e.response?.data?.message);
            throw new Error(e.response?.data?.message)
        }finally {
            this.setLoading(false)
        }
    }
    async registration(email: string, password: string) {
        try {
            const response = await AuthService.registration(email, password);
            localStorage.setItem('token', response.data.assetsToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch (e) {
            console.log(e.response?.data?.message);
        }
    }
    async checkAuth() {
        this.setLoading(true);
        try {
            const response =await AuthService.checkUser();
            localStorage.setItem('token', response.data.assetsToken);
            this.setAuth(true);
            this.setUser(response.data.user);
            //this.setIsAdmin(response.data.user.role.some(role=>role==='ADMIN'))
        } catch (e) {
            console.log(e.response?.data?.message);
        } finally {
            this.setLoading(false);
        }
    }
    async fetchUsers():Promise<IWorker[]>{
        this.setLoading(true);
        try {
            const response=await UsersService.fetchUsers();
            this.setUsersList(response.data)
            this.setLoading(false)
            return response.data;
        }catch (e){
            this.setLoading(false)
            throw new Error(e.toString())
        }
    }
    async createUser(user:IWorker):Promise<IWorker>{
        this.setLoading(true);
        try {
            const res=await UsersService.createUser(user)
            this.pushUser(res.data)
            return res.data
        }catch (e){
            console.log(JSON.stringify(e))
            throw new Error(e.response?.data?.message)

        }finally {
            this.setLoading(false)
        }
    }
    async saveUser(user:IWorker){
        this.setLoading(true);
        try {
            const res=await UsersService.editUser(user)
            this.updateUser(res.data);
          //  this.pushUser(res.data)
        }catch (e){
            console.log(JSON.stringify(e))
            this.setLoading(false)
            throw new Error(e.response?.data?.message)

        }
        this.setLoading(false)
    }
    async deleteUser(id:number){
        this.setLoading(true)
        try {
            await UsersService.deleteUser(id)
            this.setUsersList(this.usersList.filter(item=>item.id!==id))
        }catch (e){
            console.log(JSON.stringify(e))
            this.setLoading(false)
            throw new Error(e.response?.data?.message)
        }finally {
            this.setLoading(false)

        }
    }
    async fetchCountersType(){
        this.setLoading(true);
        try {
            const res=await CountersTypeService.fetchCountersType();
            this.setCountersType(res.data)
            this.setLoading(false)

        }catch (e){
            this.setLoading(false)
            throw new Error(e.toString())
        }

    }
    async getAutocomplete(){
        this.setLoading(true);

        try {
            const res=await CountersTypeService.getAutocomplete();
            this.setFifsList(res.data.fifNumber)
            this.setMethodCT(res.data.method)
            this.setNameCT(res.data.name)
            this.setManufacturerCT(res.data.manufacturer)
            this.setLoading(false)
        }catch (e){
            this.setLoading(false)
            throw new Error(e.toString())
        }
    }
    async createType(type:ICounterType){
        this.setLoading(true);
        try{
            console.log(JSON.stringify(type))
            const res=await CountersTypeService.createType(type)
            this.pushTypeCounter(res.data)
        }catch (e){
            this.setLoading(false)
            console.log(JSON.stringify(e))
            throw new Error(e.response?.data?.message)
        }
        this.setLoading(false)

    }
    async saveCounterType(type:ICounterType){
        this.setLoading(true)
        try{
            const res=await CountersTypeService.saveType(type);
            this.updateCounter(res.data);
        }catch (e) {
            console.log(JSON.stringify(e))
            throw new Error(e.response?.data?.message)
        }finally {
            this.setLoading(false)
        }
    }
    async deleteType(id:number){
        this.setLoading(true)
        try{
            await CountersTypeService.deleteType(id);
            this.deleteTypeCounterFromList(id);
        }catch (e){
            console.log(JSON.stringify(e))
                throw new Error(e.response?.data?.message)
        }finally {
            this.setLoading(false)
        }
    }
    async resetPassword(phone:string){
        try{
            this.setLoading(true)
            const res=await AuthService.resetPassword(phone);
        }catch (e){
            throw new Error(e.response.data.message.toString())
        }finally {
            this.setLoading(false)
        }
    }

}
const normalPhone = (value:string) => {
    const phoneHumber=parsePhoneNumberFromString(value,'RU');
    if (!phoneHumber){
        console.log('error')
        return value;
    }
    return (
        phoneHumber.formatInternational()
    )
}
const methodRender = (array:Reference[]|undefined,method:string):String => {
    if (typeof array==='undefined'){
        return '';
    }
    try{
        let answer='';
        const list=array.filter(item=>item.type===method)
        list.map(item=>{answer+=`${item.name}, `})
        return answer;
    }catch (e){
        return ''
    }
}