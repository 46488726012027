import {IWorker} from "../models/IWorkers";
import {makeAutoObservable} from "mobx";
import {ICity} from "../models/ICity";
import {UsersService} from "../service/UsersService";
import {IArshin} from "../models/IArshin";

export default class UsersArshinStore{
    usersList=[] as IArshin[]
    isLoading = false;
    citiesList=[]as string[]

    constructor() {
        makeAutoObservable(this);
    }
    setLoading(bool: boolean) {
        this.isLoading = bool;
    }
    setUsersList(users:IArshin[]){
        this.usersList=users
    }
    pushUser(user:IArshin){
        this.usersList.push(user);
    }
    setCities(cities:ICity[]){
        this.citiesList=cities.map(item=>{return item.name});
    }
    updateUser(user:IArshin){
        this.setUsersList(this.usersList.map(item=>(item.id===user.id)?user:item))
    }
    async fetchUsers():Promise<IWorker[]>{
        this.setLoading(true);
        try {
            const response=await UsersService.fetchUsersArshin();
            this.setUsersList(response.data)
            return response.data;
        }catch (e){
            throw new Error(e.toString())
        }finally {
            this.setLoading(false)

        }
    }
    async createUser(data:IArshin):Promise<IWorker>{
        this.setLoading(true);
        try {
            const res=await UsersService.createUserArshin(data)
            this.pushUser(res.data)
            return res.data
        }catch (e){
            console.log(JSON.stringify(e))
            throw new Error(e.response?.data?.message)

        }finally {
            this.setLoading(false)
        }
    }
    async saveUser(user:IArshin){
        this.setLoading(true);
        try {
            const res=await UsersService.saveArshin(user)
            this.updateUser(res.data);
            //  this.pushUser(res.data)

        }catch (e){
            console.log(JSON.stringify(e))
            this.setLoading(false)
            throw new Error(e.response?.data?.message)

        }
        this.setLoading(false)
    }

    async deleteUser(id:number){
        this.setLoading(true)
        try {
            await UsersService.deleteArshin(id)
            this.setUsersList(this.usersList.filter(item=>item.id!==id))
        }catch (e){
            console.log(JSON.stringify(e))
            this.setLoading(false)
            throw new Error(e.response?.data?.message)
        }finally {
            this.setLoading(false)

        }
    }
}