import {AxiosResponse} from "axios";
import {AuthResponse} from "../models/response/AuthResponse";
import $api from "../http";
import {CompanyDadataDto} from "../models/dadata/party";
import $dadata from "../http/dadata_index";

export class AuthService{
    static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/auth/login', {phone:email, password:password})
    }

    static async registration(email: string,name:string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/auth/registration', {email, name,roles:["Admin"]})
    }
    static async checkUser():Promise<AxiosResponse<AuthResponse>> {
        return $api.get<AuthResponse>('/auth/checkUser')
    }

    static async logout(): Promise<void> {
        return $api.post('/auth/logout')
    }
    static async suggestionsCopany(query:string):Promise<AxiosResponse<CompanyDadataDto>>{
        return  $dadata.post('/party',{query:query});
    }
    static async resetPassword(phone:string):Promise<AxiosResponse<string>>{
        return $api.post('/auth/resetPassword',{phone:phone})
    }
}