import {useState} from "react";

export default function useInputMaterial(initialValue:string){
    const [value,setValue]=useState(initialValue);
    function onChange(e:any){
        setValue(e.target.value);
    }
    function clear(){
        setValue('')
    }
    function isEmpty():boolean{
        if (value.length==0){
            return true
        }
        return false;
    }
    return {
        value,onChange,clear,isEmpty,setValue
    }
}