import {makeAutoObservable} from "mobx";
import {ICustomer} from "../models/ICustomer";
import CustomersService from "../service/CustomersService";

export default class Customer_store{
    isLoading = false;
    customers=[]as ICustomer[]
    setLoading(bool: boolean) {
        this.isLoading = bool;
    }
    constructor() {
        makeAutoObservable(this)
    }
    setCustomers(customers:ICustomer[]){
        this.customers=customers;
    }
    async fetchCustomer(){
        this.setLoading(true)
        try {
            const res=await CustomersService.fetchCustomers();
            this.setCustomers(res.data)
        }catch (e){
            throw new Error(e)
        }finally {
            this.setLoading(false)
        }
    }
}