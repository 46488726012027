import {AxiosResponse} from "axios";
import {ICounterType} from "../models/ICounterType";
import $api from "../http";
import {GetAutocomplete} from "../models/response/getAutocomplete";

export  class CountersTypeService{
    static async fetchCountersType():Promise<AxiosResponse<ICounterType[]>>{
        return $api.get('/counters/getCounters');
    }
    static async getAutocomplete():Promise<AxiosResponse<GetAutocomplete>>{
        return $api.get('/counters/getAutocomplete')
    }
    static async createType(ct:ICounterType):Promise<AxiosResponse<ICounterType>>{
       return  $api.post('/counters/createType',ct)
    }
    static async saveType(ct:ICounterType):Promise<AxiosResponse<ICounterType>>{
        return $api.post('/counters/save',ct)
    }
    static async deleteType(id:number):Promise<AxiosResponse<any>>{
        return $api.delete('/counters/'+id);
    }
}