import {useState} from "react";
import useValidation from "./useValidation";

export default function useInput(initialValue:string,validations:any){
    const [value,setValue]=useState(initialValue);
    const [isDirty,setDirty]=useState(false);
    const [error,setError]=useState('');
    const valid=useValidation(value,validations);
    function onChange(e:any){
        setValue(e.target.value);
    }
    function clear(){
        setValue('')
    }
    function onBlur(e:any){
        setDirty(true)
    }
    return {
        value,onChange,...valid,isDirty,onBlur,error,setError,setValue,clear
    }
}