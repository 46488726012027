import React, {FC, useContext} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {NavLink, useHistory} from "react-router-dom";
import {LOGIN_ROUTE, REPORTS} from "../utils/consts";
import {Button} from "react-bootstrap";
import {Avatar} from "@material-ui/core";

const NavBar:FC = () => {
    const {store} = useContext(Context);
    const history=useHistory();

    const logOut=()=>{
        store.setAuth(false);
        localStorage.removeItem('token')
        history.replace(LOGIN_ROUTE);
    }
    return (
        <nav
            className="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white"
            id="sidenavAccordion">
            <NavLink className={"nav-link"} to={REPORTS}>
                <img src={"assets/img/a_50.png"} alt={'title'} />
                </NavLink>
            <button className="btn btn-icon btn-transparent-dark order-1 order-lg-0 mr-lg-2" id="sidebarToggle"><i
                data-feather="menu"></i></button>
            <ul className="navbar-nav align-items-center ml-auto">
                <li className="nav-item dropdown no-caret mr-3 mr-lg-0 dropdown-user">
                    <a className="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage"
                       href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true"
                       aria-expanded="false"><img className="img-fluid"
                                                  src="assets/img/illustrations/profiles/profile-1.png"/></a>
                    <div className="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
                         aria-labelledby="navbarDropdownUserImage">
                        <h6 className="dropdown-header d-flex align-items-center">
                            <img className="dropdown-user-img" src="assets/img/illustrations/profiles/profile-1.png"/>
                            <div className="dropdown-user-details">
                                <div className="dropdown-user-details-name">{store.user.name}</div>
                                <div className="dropdown-user-details-email">{store.user.user}</div>
                            </div>
                        </h6>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#!">
                            <div className="dropdown-item-icon"><i data-feather="settings"></i></div>
                            Профиль
                        </a>
                        <Button onClick={()=>logOut()} className="dropdown-item text-left">
                            <div className="dropdown-item-icon"><i data-feather="log-out"></i></div>
                            Выйти
                        </Button>
                    </div>
                </li>
            </ul>
        </nav>

    );
};

export default observer(NavBar);