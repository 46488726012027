import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {Context} from "../index";
import Main from "../components/Main";
import {Button} from "react-bootstrap";
import {IUser} from "../models/IUser";
import AddUser from "../components/modals/AddUser";
import {useSnackbar} from "notistack";
import {observer} from "mobx-react-lite";
import {IWorker, Reference} from "../models/IWorkers";
import EditWorker from "../components/modals/EditWorker";
import {DataGrid, GridColDef} from "@material-ui/data-grid";
import parsePhoneNumberFromString from "libphonenumber-js";
import Header from "../components/Header";

const columns :GridColDef[]= [
    {
        field: 'name',
        headerName: 'ФИО',
        minWidth:250,
        type:'string',
        editable: false,
    },
    {
        field: 'cityName',
        headerName: 'Город',
        editable: false,
        minWidth:200
    },

    {
        field: 'phoneData',
        headerName: 'Телефон',
        description: 'Телефон',
        type: 'string',
        minWidth:200,
        editable: false,

    },
    {
        field: 'deadLine',
        headerName: 'Срок слкдующей оплаты',
        type: 'dateTime',
        minWidth:200,

    },
    {
        field: 'referenceM',
        headerName: 'Эталон по МИ 1592-2015',
        type: 'string',
        description:'Эталон по МИ 1592-2015',
        minWidth:300,
        editable: false,
    },
    {
        field: 'referenceG',
        headerName: 'Эталон по ГОСТ 8.156-83',
        description:'Эталон по ГОСТ 8.156-83',
        type: 'string',
        editable: false,
        minWidth:300,

    },

];
const methodRender = (array:Reference[]|undefined,method:string):String => {
    if (typeof array==='undefined'){
        return '';
    }
    try{
        let answer='';
        const list=array.filter(item=>item.type===method)
        list.map(item=>{answer+=`${item.name}, `})
        return answer;
    }catch (e){
        return ''
    }
}


const Users:FC = () => {

    const {store}=useContext(Context);
    const [show,setShow]=useState(false);
    const [showEdit,setShowEdit]=useState(false);
    const [selectUser,setSelectUser]=useState({} as IWorker);
    const { enqueueSnackbar } = useSnackbar();
    const [usersList,setUsers]=useState([] as IWorker[]);
    const normalPhone = (value:string) => {
        const phoneHumber=parsePhoneNumberFromString(value,'RU');
        if (!phoneHumber){
            console.log('error')
            return value;
        }
        return (
            phoneHumber.formatInternational()
        )
    }
    const [filter,setFilter]=useState('[]')
    document.title = "Пользователи"


    const createUser= async (user:IUser)=>{
        enqueueSnackbar('Пользователь создан',{variant:'success'})
        setUsers([...usersList,user])
        setShow(false)
    }
    const select=(user:IWorker)=>{
        setSelectUser(user);
        setShowEdit(true)
    }
    useEffect(()=>{
        store.fetchUsers().then((list)=>{

        }).catch((e)=>{
            enqueueSnackbar(e.toString(),{variant:'error'})
        })
    },[])

    const deleteUser=(id:number)=>{
        store.deleteUser(id).then(()=>{
            enqueueSnackbar('Пользователь Удален',{variant:'success'})
            setShowEdit(false)
        }).catch((e)=>{
            enqueueSnackbar(e.toString(),{variant:'error'})
        })
    }
    const saveUser = () => {
        enqueueSnackbar('Пользователь обновлен',{variant:'success'})
        setShowEdit(false)
    }
    return (
        <Main>
            <Header
                buttons={<Button onClick={()=>setShow(true)}  className="btn btn-sm btn-light text-primary active mr-2">Добавить сотрудника</Button>}
                isLoading={store.isLoading}
                namePage={'Пользователи'}
            />

            <div className="container">
                <AddUser show={show} onHide={()=>setShow(false)} createUser={createUser} />
                <EditWorker show={showEdit} onHide={()=>setShowEdit(false)} saveUser={saveUser} deleteUser={(id:number)=>{deleteUser(id)}} worker={selectUser}/>
                <div className="card">
                    <div className="card-body mt-3">
                        <DataGrid
                            rows={store.usersList.map(item=>{return item})}
                            columns={columns}
                            autoHeight
                            onCellClick={(param)=>{
                                setSelectUser(param.row as IWorker)
                                setShowEdit(true)
                            }
                            }
                            onFilterModelChange={model => {
                                setFilter(JSON.stringify(model))
                            }}
                            onSortModelChange={model => {
                                //alert(JSON.stringify(model))
                            }}
                        />

                    </div>
                </div>
            </div>
        </Main>
    );

};

export default observer(Users);
/*

 */