import React, {FC, useState} from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from "@material-ui/core/Menu";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {Grid} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useInput from "../hooks/useInput";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
    }),
);
interface dataButtonAttrs{
    onSelect:(type:string,start?:string,end?:string)=>void
}

const DateButton:FC<dataButtonAttrs> = (props) => {
    const classes = useStyles();
    const [title,setTitle]=useState('За этот месяц')
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (type:string) => {
        switch (type){
            case 'today':
                setTitle('Сегодня')
                break;
            case 'yesterday':
                setTitle('Вчера')
                break;
            case 'month':
                setTitle('За этот месяц')
                break;
        }
        props.onSelect(type)
        setAnchorEl(null);
    };
    const start=useInput('',{})
    const end=useInput('',{})
    const handleSearch = () => {
        setTitle('Период')
        props.onSelect('range',start.value,end.value)
        setAnchorEl(null);
    }

    return (
        <div>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                startIcon={<CalendarTodayIcon />}
                onClick={handleClick}>
                {title}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={()=>handleClose('today')}>Сегодня</MenuItem>
                <MenuItem onClick={()=>handleClose('yesterday')}>Вчера</MenuItem>
                <MenuItem onClick={()=>handleClose('month')}>За этот месяц</MenuItem>
                <MenuItem disableTouchRipple>
                    <Grid container >
                        <Grid item xs={5}>
                            <TextField
                                label="С"
                                type="date"
                                size={"small"}
                                variant={"outlined"}
                                className={classes.textField}
                                {...start}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={false}

                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                label="ПО"
                                size={"small"}
                                variant={"outlined"}
                                type="date"
                                {...end}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={false}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSearch}>
                                Поиск
                            </Button>
                        </Grid>
                    </Grid>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default DateButton;