import React from 'react';
import {useHistory} from "react-router-dom";
import {Button} from "react-bootstrap";
import {LOGIN_ROUTE, REGISTRATION} from "../../utils/consts";

const MainHeader = () => {
    const history=useHistory();

    return (
        <header className="page-header-ui page-header-ui-light bg-white">
            <div className="page-header-ui-content">
                <div className="container px-5">
                    <div className="row gx-5 justify-content-center">
                        <div className="col-xl-8 col-lg-10 text-center mb-4" data-aos="fade">
                            <h1 className="page-header-ui-title">Представляем вам систему мобильный аршин</h1>
                            <p className="page-header-ui-text">Поверяте счетчики прямо с телефона и отправляйте данные в единую систему</p>
                            <Button onClick={()=>history.push(REGISTRATION)}>Попробовать бесплатно</Button>
                            <a className="btn btn-link fw-500" href="#!">Подробнее</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="svg-border-rounded text-light">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none"
                     fill="currentColor">
                    <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
                </svg>
            </div>
        </header>
    );
};

export default MainHeader;