import React, {FC, useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {List, ListItem, ListItemSecondaryAction, ListItemText, TextField} from "@material-ui/core";
import InputMask from "react-input-mask";
import AutocompleteInput from "../widgets/AutocompleteInput";
import {Autocomplete} from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import useInputMaterial from "../../hooks/useInputMaterial";
import useAutocomplete from "../../hooks/useAutocomplete";
import {Context} from "../../index";
import {useSnackbar} from "notistack";
import {IWorker} from "../../models/IWorkers";
import {IArshin} from "../../models/IArshin";
import CityAutocomplete from "../dadata/city_autocomplete";
import {SuggestionCity} from "../../models/dadata/city";


interface props{
    show:boolean;
    onHide:any;
    createUser:any
}


const AddUserArshin:FC<props> = (props) => {
    const nameUser=useInputMaterial('');
    const cityUser=useAutocomplete('')
    const {usersArshin}=useContext(Context);
    const { enqueueSnackbar } = useSnackbar();
    const  create=async ()=>{

        usersArshin.createUser({name:nameUser.value,city:cityUser.value} as IArshin).then((worker)=>{
            props.createUser(worker)
        }).catch((e)=>{
            enqueueSnackbar(e.toString(),{variant:'error'})
        })
    }
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            centered

        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Создание пользователя для аршина
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TextField label={'Имя пользователя'} fullWidth={true} className={"mt-3"} {...nameUser}  variant={"outlined"} />
                <CityAutocomplete setCity={(e:SuggestionCity)=> cityUser.setValue(e.data.city)}/>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-danger" onClick={props.onHide}>Отмена</Button>
                <Button variant="outline-success" onClick={create}>Создать</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default observer(AddUserArshin);