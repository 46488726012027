import {useState} from "react";

export default function useAutocomplete(initialValue:string){
    const [value,setValue]=useState(initialValue);
    function onChange(e:string){
        setValue(e);
    }
    function clear(){
        setValue('')
    }
    return{
        value,setValue,onChange,clear
    }
}