import React, {useEffect, useState} from 'react';
import {LinearProgress} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {CompanyService} from "../service/CompanyService";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {COMPANY} from "../utils/consts";

function getQueryVariable(variable:string):string|false
{
    let query = window.location.search.substring(1);
    console.log(query)//"app=article&act=news_content&aid=160990"
    let vars = query.split("&");
    console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (let i=0;i<vars.length;i++) {
        let pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
    }
    return(false);
}
const PaymentCheck = () => {
    const [error,setError]=useState('')
    const [susses,setSusses]=useState('');
    const [show,setShow]=useState(false)
    const history=useHistory();

    useEffect(()=>{
        const result=getQueryVariable('result_code')
        if (result==false){
            setError('Код не найден')
            setShow(true)
        }
        if (typeof result === "string") {
            CompanyService.checkPayment(result).then(value => {
                setSusses(`Оплата прошла успешно`)
                setShow(true)
            }).catch(e=>{
                setError('Оплата не проведена:  Отказ банка – эмитента карты.  Ошибка в процессе оплаты, указаны неверные данные карты')
                setShow(true)
            })
        }else{
            setError('Код не найден')
            setShow(true)

        }

    },[])
    const goBack = () => {
        history.replace(COMPANY)
    }
    return (
        <div>
            <LinearProgress hidden={show}/>
            <p>Проверяем ваш платеж</p>
            {
                (error!='')&&<Alert severity="error" action={
                    <Button color="inherit" size="small" onClick={goBack}>
                        В систему
                    </Button>
                }>{error}</Alert>
            }
            {
                (susses!='')&&<Alert severity="success" action={
                    <Button color="inherit" size="small" onClick={goBack}>
                        В систему
                    </Button>
                }>{susses}</Alert>
            }
        </div>
    );
};

export default PaymentCheck;