import React, {FC, useContext, useEffect, useState} from 'react';
import useInput from "../../hooks/useInput";
import {IWorker, Reference} from "../../models/IWorkers";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import TextInput from "../widgets/TextInput";
import InputMask from "react-input-mask";
import {List, ListItem, ListItemSecondaryAction, ListItemText, TextField} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {observer} from "mobx-react-lite";
import AutocompleteInput from "../widgets/AutocompleteInput";
import useAutocomplete from "../../hooks/useAutocomplete";
import {useSnackbar} from "notistack";
import {Context} from "../../index";
import {Autocomplete} from "@material-ui/lab";
import useInputMaterial from "../../hooks/useInputMaterial";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {SuggestionCity} from "../../models/dadata/city";
import CityAutocomplete from "../dadata/city_autocomplete";

interface props{
    show:boolean;
    onHide:any;
    saveUser:any
    deleteUser:any
    worker:IWorker
}

const EditWorker:FC<props> = (props) => {
    const nameUser=useInputMaterial('');
    const [referenceType,setReferenceType]=useState('')
    const reference=useInputMaterial('');
    const passUser=useInputMaterial('');
    const cityUser=useAutocomplete('')
    const [phone,setPhone]=useState('');

    const {store}=useContext(Context);
    const { enqueueSnackbar } = useSnackbar();
    const [referenceList,setReferenceList]=useState([] as Reference[]);
    const [role, setRole] = React.useState('');



    useEffect(()=>{
        setReferenceList([])
        nameUser.setValue(props.worker.name??'')
        cityUser.setValue(props.worker.cityName??'')
        setPhone(props.worker.phone??'')
        let rol='';
        if(props.worker.roles!=undefined){
            rol=props.worker.roles[0].value??''
        }
        setRole(rol)
        props.worker.reference?.map(item=>{
            setReferenceList([...referenceList,item]);
        })
    },[props.worker])
    const deleteUser=()=>{
        props.deleteUser(props.worker.id)
    }
    const create=()=>{
        if (cityUser.value==''){
            enqueueSnackbar('Укажите город',{variant:'error'})
            return;
        }
        let login=parseInt(phone.replace(/[^\d]/g, '')).toString().substring(1);

        const user={
            name:nameUser.value,
            password:passUser.value,
            cityName:cityUser.value,
            phone:login,
            roles:[role],
            references:referenceList,
            id:props.worker.id
        } as IWorker;
        store.saveUser(user).then(()=>{
            props.saveUser()
            nameUser.clear();
            passUser.clear()
            cityUser.clear()
            setPhone('')
            setReferenceList([])
        }).catch((e)=>{
            enqueueSnackbar(e.toString(),{variant:'error'})
        })

    }
    const deleteRow=(name:Reference)=>{
        setReferenceList(referenceList.filter(item=>item!==name));

    }
    const addReference=()=>{
        if (reference.isEmpty()){
            return;
        }

        setReferenceList([...referenceList,{name:reference.value,type:referenceType}]);
        reference.clear()
        setReferenceType('')
    }
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            centered
            size="lg"

        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Редактор пользователя {props.worker.id}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <TextField label={'Имя пользователя'} fullWidth={true} className={"mt-3"} {...nameUser}  variant={"outlined"} />
                        <InputMask
                            mask="+7 (999) 999-9999"
                            value={phone}
                            disabled={false}
                            onChange={e => setPhone(e.target.value)}
                        >
                            {() => <TextField variant={"outlined"} className={"mt-3"} fullWidth={true} label={'Телефон'}/>}
                        </InputMask>
                        <TextField label={'Пароль пользователя'} className={"mt-3"} fullWidth={true} {...passUser}  variant={"outlined"} />
                        <CityAutocomplete setCity={(e:SuggestionCity)=> cityUser.setValue(e.data.city)}/>
                    </Col>
                    <Col>
                        <div>
                            <Row>
                                <Col>
                                    <TextField
                                        fullWidth={true}
                                        {...reference}
                                        label="Эталон"
                                        margin="normal"
                                        variant="outlined" />
                                </Col>
                                <Col>
                                    <Autocomplete
                                        freeSolo
                                        id="free-solo-2-demo"
                                        disableClearable
                                        options={['МИ 1592-2015','ГОСТ 8.156-83']}
                                        onChange={(event,newValue)=>{
                                            if (typeof newValue === 'string') {
                                                setReferenceType(newValue)
                                            }
                                        }}
                                        value={referenceType}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Метод"
                                                margin="normal"
                                                value={referenceType}
                                                onChange={e=>setReferenceType(e.target.value)}
                                                variant="outlined"
                                                InputProps={{ ...params.InputProps, type: 'search' }}
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>

                            <Button
                                variant={'outline-primary'}
                                className={'btn-block mt-3'}
                                onClick={addReference}
                            >Добавить</Button>
                            <List >
                                {
                                    referenceList.map(item=>{
                                        return (
                                            <ListItem>
                                                <ListItemText
                                                    primary={item.name}
                                                    secondary={item.type}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="delete">
                                                        <DeleteIcon onClick={()=>deleteRow(item)}/>
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-danger" className={"mr-auto"} onClick={deleteUser}>Удалить</Button>
                <Button variant="outline-danger" onClick={props.onHide}>Отмена</Button>
                <Button variant="outline-success" onClick={create}>Сохранить</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default observer(EditWorker);