import React, {FC} from 'react';
import LinearProgress from "@material-ui/core/LinearProgress";
import {IErrors} from "../models/IErrors";
import {Alert} from "@material-ui/lab";
import {Simulate} from "react-dom/test-utils";


interface components{
    namePage:string,
    isLoading:boolean;
    buttons:any
    errors?:IErrors[]
}
const Header: FC<components>=(c:components) => {
    return (
        <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
            <div className="container-fluid">
                <div className="page-header-content">
                    <div className="row align-items-center justify-content-between pt-3">
                        <div className="col-auto mb-3">
                            <h1 className="page-header-title">
                                <div className="page-header-icon"><i data-feather="file"></i></div>
                                {c.namePage}
                            </h1>
                        </div>
                        <div className="col-12 col-xl-auto mb-3">
                            {
                                c.buttons
                            }
                        </div>

                    </div>
                </div>
            </div>
            <LinearProgress hidden={!c.isLoading}/>
            {
                c.errors?.map(item=>{
                    return <Alert severity="error">{item.text}</Alert>
                })
            }

        </header>
    );
};

export default Header;