import React, {useContext, useEffect} from 'react';
import {Context} from "../index";
import {useSnackbar} from "notistack";
import MUIDataTable, {MUIDataTableColumnDef, MUIDataTableOptions} from "mui-datatables";
import Main from "../components/Main";
import Header from "../components/Header";
import {Button} from "react-bootstrap";
import AddRequest from "../components/modals/AddRequest";
import moment from "moment";
import {Chip} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckIcon from "@material-ui/icons/Check";
import {observer} from "mobx-react-lite";
const SPACED_DATE_FORMAT = "YYYY-MM-DD";


const columns:MUIDataTableColumnDef []= [
    {
        name: 'city',
        label: 'Город',
    },
    {
        name: 'name',
        label: 'Имя',
        //description: 'Реальный создатель отчета',
        //  minWidth:200,
        //  type:'string',
        //  editable: false,
    },
    {
        name: 'phone',
        label: 'Телефон',
        //  type: 'string',
        //  minWidth:200,
        //    editable: false,

    },
    {
        name: 'email',
        label: 'email',

        //  description: 'Номер ФИФ',
        //  type: 'string',
        //    editable: false,
    },


];

const Cusromers = () => {
    const {customers}=useContext((Context))
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        customers.fetchCustomer().then().catch(e=>{
            enqueueSnackbar(e.message)
        })
    },[])
    const options:MUIDataTableOptions = {
        filter: true,
        filterType: "dropdown",
        selectableRows: "none",
        draggableColumns: {
            enabled: true
        },
        responsive: "standard",
        onFilterChange:(changedColumn,filterList,type,changedColumnIndex,displayData)=>{
            console.log(JSON.stringify(displayData));
            /*
            const idArray=displayData.map(item=>{
                return reports.reports[item.dataIndex].id;
            });
            setFilters(JSON.stringify(idArray));

             */
        },
        onSearchChange:(searchText)=>{

        },
        onRowClick:(rowData: string[], rowMeta: { dataIndex: number; rowIndex: number })=>{
            // setSelectReport(reports.reports[rowMeta.rowIndex]);
            //   setShowEdit(true)
        },
        search:false,
        print:false,
        download:false,
        textLabels: {
            body: {
                noMatch: "Ничего не найдено",
                toolTip: "Поиск",
                columnHeaderTooltip: column => `Поиск по ${column.label}`
            },
            pagination: {
                next: "Следующая страница",
                previous: "Прошлая страница",
                rowsPerPage: "Строк на странице:",
                displayRows: "of",
            },
            toolbar: {
                search: "Поиск",
                downloadCsv: "Скачать отчет",
                print: "Print",
                viewColumns: "Столбцы",
                filterTable: "Фильтры",
            },
            filter: {
                all: "Все",
                title: "Фильтры",
                reset: "Сборсть фильтры",
            },
            viewColumns: {
                title: "Список столбцов",
                titleAria: "Скрыть/показать столбец",
            },
            selectedRows: {
                text: "Строк выбранно",
                delete: "Удалить",
                deleteAria: "Удалить выделенные строки",
            },
        },
    };
    document.title = "Клиенты"

    return (
        <Main>
            <Header
                buttons={''}
                isLoading={customers.isLoading}
                namePage={'Клиенты'}
            />
            <div className="container">
                <MUIDataTable
                    title={"Список клиентов"}
                    data={customers.customers.map(item=>{return item})}
                    columns={columns}
                    options={options}
                />
            </div>

        </Main>

    );
};

export default observer(Cusromers);