import React, {FC, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useInputMaterial from "../../hooks/useInputMaterial";
import {Box, CardActions, Checkbox, Divider, FormControlLabel, Grid, Paper} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {Alert} from "@material-ui/lab";

interface dialogInterface{
    show:boolean;
    handleClose:()=>void,
    createPayment:(cost:number)=>void
    createPaymentCard:(cost:number)=>void

}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            height:'100%'
        },
        textAlign:{
            padding: theme.spacing(2),
            textAlign: 'center',
        }
    }),
);
const PaymentDialog:FC<dialogInterface> = (props) => {
    const cost=useInputMaterial('');
    const classes = useStyles();

    const create = () => {
        setErrorMessage('')
        if (cost.value==''){
          setErrorMessage('Укажите сумму платежа')
          return;
      }
      props.createPayment(Number(cost.value))
    }
    const card = () => {
        setErrorMessage('')
        if (cost.value==''){
            alert('Укажите сумму платежа')
            setErrorMessage('Укажите сумму платежа')
            return;
        }
        if (!checked){
            alert('Примите условия офферты')
            setErrorMessage('Примите условия офферты')
            return;
        }
        props.createPaymentCard(Number(cost.value))
    }
    const [checked,setChecked]=useState(true);
    const [errorMessage,setErrorMessage]=useState('')
    return (

        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={props.show}
        >
            <DialogTitle >Пополнение</DialogTitle>
            <DialogContent>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {
                            (errorMessage!=='')??<Alert severity="error">{errorMessage}</Alert>
                        }
                        <Box className={classes.textAlign}>
                            <TextField
                                autoFocus
                                style ={{width: '40%'}}
                                margin="dense"
                                label="Укажите сумму платежа"
                                type="number"
                                {...cost}
                                variant="outlined"
                            />
                        </Box>

                    </Grid>
                    <Grid item xs={6}>
                        <Card className={classes.paper}>
                            <CardActionArea >

                                <CardContent style={{height:262}}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Сформировать счет
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" align="left">
                                        Сформируйте счет для оплаты от Юр. лица. Срок зачисления до 5 рабочих дней
                                    </Typography>
                                </CardContent>
                            </CardActionArea>

                            <CardActions style={{display:'flex', justifyContent: "space-between"}}>
                                <Button onClick={create} fullWidth={true}  variant={"contained"} color={'secondary'}>
                                    Выбрать
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card  className={classes.paper}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Оплата картой
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p" align="left">
                                        При оплате заказа банковской картой ввод реквизитов карты происходит в системе электронных платежей ПАО АКБ «Авангард», который прошел сертификацию в платежных системах Visa, MasterCard и МИР. Представленные Вами данные полностью защищены и никто, включая наш интернет-магазин, не может их получить.
                                    </Typography>

                                </CardContent>

                            </CardActionArea>
                            <FormControlLabel control={<Checkbox checked={checked} onChange={()=>{setChecked(!checked)}}/>}  label={
                                <div>
                                    <span>Принимаю</span>
                                    <a href={'docs/Оферта_информационные услуги_согласовано.pdf'} target={'_blank'}> условия офферты</a>
                                </div>
                            } />
                            <CardActions>


                                <Button onClick={card} fullWidth={true} variant={"contained"} color={'secondary'}>
                                    Выбрать
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="secondary" >
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>

    );
};

export default PaymentDialog;