import {AxiosResponse} from "axios";
import $api from "../http";
import {IWorker} from "../models/IWorkers";
import {ICity} from "../models/ICity";
import {IArshin} from "../models/IArshin";
import {UserSuggestion} from "../models/UserSuggestion";

export class UsersService{
    static async fetchUsers(): Promise<AxiosResponse<IWorker[]>>{
        return $api.get('/user/getUsers')
    }
    static async fetchUsersArshin(): Promise<AxiosResponse<IArshin[]>>{
        return $api.get('/arshin/getUsers')
    }
    static async createUser(user:IWorker):Promise<AxiosResponse<IWorker>>{
        return $api.post('/user/registrationUser',user);
    }
    static async createUserArshin(data:IArshin):Promise<AxiosResponse<IArshin>>{
        return $api.post('/arshin/create',data);
    }
    static async deleteUser(id:number):Promise<AxiosResponse<any>>{
        return $api.delete('/user/'+id,);
    }
    static async fetchCity():Promise<AxiosResponse<ICity[]>>{
        return $api.get('/user/getCityList')
    }
    static async editUser(user:IWorker):Promise<AxiosResponse<IWorker>>{
        return $api.post('/user/saveUser',user);
    }
    static async autocompleteUsers(name:string):Promise<AxiosResponse<UserSuggestion[]>>{
        return $api.post('/user/autocompleteUsers',{'name':name});
    }
    static async saveArshin(user:IArshin):Promise<AxiosResponse<IArshin>>{
        return $api.post('/arshin/saveUser',user);
    }
    static async deleteArshin(id:number):Promise<AxiosResponse<any>>{
        return $api.delete('/arshin/delete/'+id,);
    }
}