import React, {FC, useEffect} from 'react';
import {SuggestionAddress} from "../../models/dadata/address";
import DadataService from "../../service/DadataService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

interface addressAutocompleteInterface{
    setAddress:Function
}
const AddressAutocomplete:FC<addressAutocompleteInterface> = ({setAddress}) => {
    const [value, setValue] = React.useState<SuggestionAddress | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<SuggestionAddress[]>([]);
    useEffect(()=>{
        async function getCompany(){
            try{
                let newOptions = [] as SuggestionAddress[];

                if (value) {
                    newOptions = [value];
                }else {
                    const response=await DadataService.fetchAddress(inputValue)
                    response.data.suggestions?.map(item=>{
                        newOptions.push(item);
                    })
                }
                setOptions(newOptions);


            }catch (e) {

            }
        }
        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        getCompany();

    },[value,inputValue])


    return (
        <Autocomplete
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.value)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            noOptionsText={'Адрес не найден'}
            value={value}
            onChange={(event: any, newValue: SuggestionAddress | null) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                setAddress(newValue)
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label="Адрес" variant="outlined" fullWidth style={{marginBottom:'10px',marginTop:'10px'}}/>
            )}
            renderOption={(option) => {

                return (
                    <Grid container alignItems="center">
                        <Grid item>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2">
                                {option.value}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {`${option.data.region}`}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
};

export default AddressAutocomplete;