import {makeAutoObservable} from "mobx";
import ReportService from "../service/ReportService";
import {IReport} from "../models/IReport";
import {IWorker} from "../models/IWorkers";

export default class Reports_store{
    isLoading = false;
    reports=[] as IReport[]
    page=0;

    setLoading(bool: boolean) {
        this.isLoading = bool;
    }
    setReports(reports:IReport[]){
        this.reports=reports;
    }
    constructor() {
        makeAutoObservable(this);
    }
    updateReport(report:IReport){
        this.setReports(this.reports.map(item=>(item.id===report.id)?report:item))
    }

    async fetchReports():Promise<IReport[]>{
        this.setLoading(true)
        try{
            const res=await ReportService.fetchReports();
            this.setReports(res.data.map(item=>{
                return {
                    ...item,
                    'city':item.arshin?.cityName,
                    'dateReport':new Date(item.createdAt??0),
                    'fif':item.number,
                    'method':item.method,
                    'userName':item.arshin?.name,
                    'creatorName':item.creator?.name,
                    'nextReportData':item.nestChek,
                    'temperature':item.temperature+' ℃',
                    'humidity':item.humidity+' %',
                    'pressure':item.pressure+' кПа'

                }
            }))
            return res.data;
        }catch (e){
            throw new Error(e)
        }finally {
            this.setLoading(false)
        }
    }

    async deleteReport(id:number):Promise<boolean>{
        this.setLoading(true)
        try {
            const res=await ReportService.deleteReport(id)
            this.setReports(this.reports.filter(item=>item.id!==id))
            return true
        }catch (e){
            throw new Error(e)
        }finally {
            this.setLoading(false)
        }
    }
    async editReport(data:IReport):Promise<IReport>{
        this.setLoading(true)
        try{
            const res=await ReportService.editReport(data);
            this.updateReport(res.data);
            return res.data
        }catch (e){
            throw new Error(e)
        }finally {
            this.setLoading(false)
        }
    }


}