import React, {FC, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import AppRouter from "./components/AppRouter";
import {BrowserRouter} from "react-router-dom";
import { SnackbarProvider} from 'notistack';
import {store} from "./index";
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import { createTheme, ThemeProvider} from "@material-ui/core/styles";
import {ruRU} from "@material-ui/data-grid";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976d2' },
        },
    },
    ruRU,
);


const App: FC = () => {
    useEffect(() => {

        if (localStorage.getItem('token')) {
            store.checkAuth().then().finally();
        }
    }, [])

    return (
      <SnackbarProvider maxSnack={3} >
          <BrowserRouter>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={theme}>
                      <AppRouter />
                  </ThemeProvider>
              </MuiPickersUtilsProvider>
          </BrowserRouter>
      </SnackbarProvider>
  );
}

export default observer(App);
