import {makeAutoObservable} from "mobx";
import {CompanyData, Order} from "../models/response/CompanyData";
import {CompanyService} from "../service/CompanyService";

export default class Company_store{

    isLoading = false;
    companyData={} as CompanyData;
    setLoading(bool: boolean) {
        this.isLoading = bool;
    }
    constructor() {
        makeAutoObservable(this)
    }
    setCompanyData(data:CompanyData){
        this.companyData=data;
    }
    async loadMyCompanyData():Promise<CompanyData>{
        try {
            this.setLoading(true);
            const res=await CompanyService.getCompanyInfo();
            this.setCompanyData(res.data)
            return res.data;
        }catch (e){
            throw new Error(e)
        }finally {
            this.setLoading(false);
        }
    }
    async createOrder(cost:number,type=0):Promise<Order>{
        try{
            this.setLoading(true);
            const res=await CompanyService.createOrder(cost,type);
            this.companyData.orders?.push(res.data)
            return res.data
        }catch (e){
            throw new Error(e)
        }finally {
            this.setLoading(false);

        }
    }
    async createPayment(sum:number){
        const order=await this.createOrder(sum,1);
        this.openOrder(order);

    }
    async createInvoice(id:number){
       //return  await CompanyService.createInvoice(id);
    }
    async changeTariff(tariff:number){
        try{
            this.setLoading(true);
            const res=await CompanyService.changeTariff(tariff);
            this.setCompanyData(res.data)
        }catch (e){
            throw new Error(e.response.data.message)
        }finally {
            this.setLoading(false);

        }
    }
    async openOrder(order:Order){
        if(order==undefined){
            return;
        }
        if (order.type===1){
            window.open(`https://pay.avangard.ru/iacq/pay?ticket=${order.ticket}`,"_blank");
        }else {
            try{
                const invoice=await this.createInvoice((order.id!=undefined)?order.id:0);
                alert(JSON.stringify(invoice))
                window.open(`https://mobile-arshin.ru/api/${invoice}`,"_blank");
            }catch (e){
                alert(e.response.data.message)
            }

        }
    }
    postForm(path:string, params:any, method='post') {
        method = method || 'post';

        var form = document.createElement('form');
        form.setAttribute('method', method);
        form.setAttribute('action', path);

        for (var key in params) {
            if (params.hasOwnProperty(key)) {
                var hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', params[key]);

                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
    }
}