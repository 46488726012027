import React, {FC, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useInputMaterial from "../../hooks/useInputMaterial";
import AddressAutocomplete from "../dadata/address_autocomplete";
import {SuggestionAddress} from "../../models/dadata/address";
import UserAutocomplete from "../autocomplete/user_autocomplete";
import {UserSuggestion} from "../../models/UserSuggestion";
import {TextInputMaterial} from "../widgets/TextInputMaterial";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {CreateRequestDto} from "../../models/dto/create-request.dto";

interface dialogInterface{
    show:boolean;
    handleClose:any,
    createRequest:any

}
const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        marginTop: theme.spacing(2),

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const AddRequest:FC<dialogInterface> = (props) => {
    const classes = useStyles();
    const comment=useInputMaterial('');
    const name=useInputMaterial('');
    const phone=useInputMaterial('');
    const email=useInputMaterial('');
    const [user,setUser]=useState({} as UserSuggestion)
    const [address,setAddress]=useState({} as SuggestionAddress)
    const [age, setAge] = React.useState('');


    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setAge(event.target.value as string);
    };

    const create = () => {

        props.createRequest({
            address:address.value,
            comment:comment.value,
            type:age,
            customer_name:name.value,
            customer_phone:phone.value,
            customer_email:email.value,
            userId:user.id
        }as CreateRequestDto)
    }
    const selectAddress = (a:SuggestionAddress) => {
      setAddress(a)
    }
    const selectUser = (u:UserSuggestion) => {
        setUser(u)
    }
    return (
        <Dialog
            open={props.show}
            fullWidth={true}
            maxWidth={'sm'}

        >
            <DialogTitle >Новая заявка</DialogTitle>
            <DialogContent>
                <DialogContentText>

                </DialogContentText>
                <AddressAutocomplete setAddress={selectAddress}/>
                <UserAutocomplete setUser={selectUser}/>
                <TextInputMaterial label={'Комментарий'} {...comment}/>
                <TextInputMaterial label={'Телефон'} {...phone}/>
                <TextInputMaterial label={'Имя клиента'} {...name}/>
                <TextInputMaterial label={'Email'} {...email}/>
                <FormControl className={classes.formControl} fullWidth={true} variant="outlined" >
                    <InputLabel id="demo-simple-select-disabled-label">Тип отчета</InputLabel>
                    <Select
                        labelId="demo-simple-select-disabled-label"
                        id="demo-simple-select-disabled"
                        value={age}
                        onChange={handleChange}
                    >

                        <MenuItem value={'Вода'}>Вода</MenuItem>
                        <MenuItem value={'Другое'}>Другое</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="secondary">
                    Отмена
                </Button>
                <Button onClick={create} color="primary">
                    Создать
                </Button>
            </DialogActions>
        </Dialog>

    );
};

export default AddRequest;