import {useState} from "react";

export const useFetching=(callback:Function)=>{
    const [isLoading,setLoading]=useState(false);
    const [error,setError]=useState('');
    const fetching =async () => {
      try {
          setLoading(true);
          await callback();
      }catch (e){
          //alert(JSON.stringify(e))
          console.log(JSON.stringify(e))
          setError(e.response.data.message);
      }finally {
          setLoading(false)
      }
    }
    return [fetching,isLoading,error] as const;
}