import {AxiosResponse} from "axios";
import {IRequest} from "../models/IRequest";
import $api from "../http";
import {CreateRequestDto} from "../models/dto/create-request.dto";

export default class RequestService{
    static async fetchRequest():Promise<AxiosResponse<IRequest[]>>{
        return $api.get('/request/getRequest');
    }
    static async createRequest(data:CreateRequestDto):Promise<AxiosResponse<IRequest>>{
        return $api.post('/request/create',data)
    }

}