import {useEffect, useState} from "react";

export default function useValidation(value:string,validations:any){
    const [isEmpty,setEmpty]=useState(true);
    const [minLengthError,setMinLength]=useState(false);
    useEffect(()=>{
        for (const validation in validations){
            switch (validation){
                case 'minLength':
                   /// value.length<validations(validation)?setMinLength(true):setMinLength(false);
                    break;
                case 'isEmpty':
                    value?setEmpty(false):setEmpty(true)
                    break;
            }
        }
    },[value])
    return{
        isEmpty,minLengthError
    }
}