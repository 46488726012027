import React, {useContext} from 'react';
import Main from "../components/Main";
import Header from "../components/Header";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {Box, createStyles, Grid, Paper} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);

const RequestTest = () => {
    const {requests}=useContext(Context)
    const classes = useStyles();


    return (
        <Main>
            <Header
                buttons={''}
                isLoading={requests.isLoading}
                namePage={'Компания'}
            />
            <Box >
                <Paper >
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Paper className={classes.paper}>xs=6</Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper className={classes.paper}>xs=6</Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Main>
    );
};

export default observer(RequestTest);