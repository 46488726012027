import React, {FC, useContext} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";

interface props{
    options:string[]
    label:string
    value:string
    setValue:any
}
const AutocompleteInput:FC<props> = (props) => {

    return (
        <Autocomplete
            freeSolo
           value={props.value}
            onChange={(e,n)=>{
                props.setValue(n?.toString())
            }}
            options={props.options.map((option) => option)}
            renderInput={(params) => (
                <TextField {...params} value={props.value} onChange={e=>props.setValue(e.target.value)} label={props.label} margin="normal" variant="outlined" />
            )}
        />
    );
};

export default AutocompleteInput;