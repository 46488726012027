import React, {FC, useContext, useEffect, useState} from 'react';
import {Button, Col, Modal, Row} from "react-bootstrap";
import {IReport} from "../../models/IReport";
import {observer} from "mobx-react-lite";
import AutocompleteInput from "../widgets/AutocompleteInput";
import useAutocomplete from "../../hooks/useAutocomplete";
import {Context} from "../../index";
import {createStyles, makeStyles, TextField, Theme} from "@material-ui/core";
import useInputMaterial from "../../hooks/useInputMaterial";
import {useSnackbar} from "notistack";
import {format} from "date-fns";

interface props{
    show:boolean;
    onHide:any;
    report:IReport
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
    }),
);
const EditReport:FC<props> = (props) => {
    const classes = useStyles();

    const cityUser=useAutocomplete(props.report.creator?.cityName??'ERROR')
    const fifNumber=useAutocomplete(props.report.number??'ERROR')
    const user=useAutocomplete('')
    const creator=useAutocomplete('')
    const modification=useAutocomplete(props.report.modification??'ERROR')
    const method=useAutocomplete(props.report.modification??'ERROR')
    const arshin=useAutocomplete('');
    const [dateCreate,setDateCreate]=useState('');
    const [dateNewCheck,setDateNewCheck]=useState('');

    const [cities,setCities]=useState([] as string[]);
    const { enqueueSnackbar } = useSnackbar();


    const reference=useInputMaterial('');
    const counterName=useInputMaterial('');
    const humidity=useInputMaterial('');
    const pressure=useInputMaterial('');
    const temperature=useInputMaterial('');

    const deleteReport = () => {
        reports.deleteReport(props.report.id??0).then(result=>{
            props.onHide()
        }).catch(e=>{
            enqueueSnackbar('EDITOR: '+e.toString(),{variant:'error'})
        })

    }

    const {store,reports,usersArshin}=useContext(Context);
    useEffect(()=>{
        store.fetchUsers().then().catch(e=>{
            enqueueSnackbar('EDITOR: '+e.toString(),{variant:'error'})
        })
        usersArshin.fetchUsers().then().catch(e=>{
            enqueueSnackbar('EDITOR: '+e.toString(),{variant:'error'})
        })
        setCities(usersArshin.usersList.filter(item=>(item.city===(props.report.creator?.cityName??''))).map(item=>item.name??''));

        cityUser.setValue(props.report.creator?.cityName??'ERROR EFFECT');
        fifNumber.setValue(props.report.number??'ERROR EFFECT');
        creator.setValue(props.report.creator?.name??'ERROR EFFECT');
        user.setValue(props.report.arshin?.name??'ERROR EFFECT');
        modification.setValue(props.report.modification??'ERROR EFFECT');
        method.setValue(props.report.method??'ERROR EFFECT');
        humidity.setValue((props.report.humidity??'ERROR EFFECT').replace(' %',''));
        pressure.setValue((props.report.pressure??'ERROR EFFECT').replace(' кПа',''));
        reference.setValue(props.report.reference??'ERROR EFFECT')
        counterName.setValue(props.report.factoryNumber??'ERROR EFFECT')
        temperature.setValue((props.report.temperature??'ERROR EFFECT').replace(' ℃',''));
        setDateCreate(format(new Date(props.report.createdAt??0), 'yyyy-MM-dd'));
        arshin.setValue(props.report.creator?.name??'ERROR EFFECT');
    },[props.report])
    const save = () => {
        const creatorId=usersArshin.usersList.find(item=>(item.name===arshin.value))
        if(!creatorId){
            alert('Укажите сотрудника из списка')
            return;
        }

      const editResult={
          id:props.report.id,
          reference:reference.value,
          modification:modification.value,
          number:fifNumber.value,
          factoryNumber:counterName.value,
          companyName:props.report.companyName,
          pressure:pressure.value,
          temperature:temperature.value,
          humidity:humidity.value,
          type:props.report.type,
          creatorId:creatorId.id,
          newCheckDate:'',
          createdAt:''
      } as IReport;
      reports.editReport(editResult).then(report=>{
          props.onHide()
      }).catch(e=>{
          enqueueSnackbar('EDITOR: '+e.toString(),{variant:'error'})

      })
    }
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            centered
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Редактор отчета
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <AutocompleteInput {...fifNumber} label={'Номер в ФИФ'} options={store.fifsList}/>
                        <AutocompleteInput {...modification} label={'Модификация СИ'} options={store.modificationsCT}/>
                        <TextField
                            fullWidth={true}
                            {...counterName}
                            label="Номер счетчика"
                            margin="normal"
                            variant="outlined" />
                        <TextField
                            fullWidth={true}
                            {...reference}
                            label="Эталон"
                            margin="normal"
                            variant="outlined" />
                        <AutocompleteInput {...arshin} label={'Пользователь аршин'} options={cities}/>


                    </Col>
                    <Col>
                        <AutocompleteInput {...method} label={'Методика проверки'} options={store.methodCT}/>
                        <TextField
                            fullWidth={true}
                            {...temperature}
                            label="Температура ℃"
                            margin="normal"
                            variant="outlined" />
                        <TextField
                            fullWidth={true}
                            {...pressure}
                            label="Давление кПа"
                            margin="normal"
                            variant="outlined" />
                        <TextField
                            fullWidth={true}
                            {...humidity}
                            label="Влажность %"
                            margin="normal"
                            variant="outlined" />
                        {
                            /*
                             <TextField
                            variant="outlined"
                            label="Дата поверки"
                            type="date"
                            defaultValue={dateCreate}
                            onChange={e=>setDateCreate(e.target.value)}
                            value={dateCreate}
                            fullWidth={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />d
                             */
                        }

                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {

                }
                <Button variant="outline-danger" className={"mr-auto"} onClick={deleteReport}>Удалить</Button>
                <Button variant="outline-danger" onClick={props.onHide}>Отмена</Button>
                <Button variant="outline-success" onClick={save}>Сохранить</Button>

            </Modal.Footer>
        </Modal>
    );
};

export default observer(EditReport);