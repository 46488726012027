import React, {FC, useContext, useEffect, useState} from 'react';
import {Context} from "../index";
import Main from "../components/Main";
import Header from "../components/Header";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {observer} from "mobx-react-lite";
import {
    Box, Chip,
    Grid,
    Paper,
    Tooltip
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {IOptions} from "../models/IOptions";
import PaymentDialog from "../components/modals/PaymentDialog";
import SelectTariff from "../components/modals/SelectTariff";
import {useSnackbar} from "notistack";
import {blue} from "@material-ui/core/colors";
import { format } from 'date-fns';
import {TariffDto} from "../models/dto/tariff.dto";





const useStyles = makeStyles((theme)=>({
    root: {
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    card : {
        transition: theme.transitions.create(["background", "background-color"], {
            duration: theme.transitions.duration.complex,
        }),
        "&:hover": {
            backgroundColor: "rgba(51,51,51,0.1)",

        },
    }

}));

function getQueryVariable(variable:string):string|false
{
    let query = window.location.search.substring(1);
    console.log(query)//"app=article&act=news_content&aid=160990"
    let vars = query.split("&");
    console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (let i=0;i<vars.length;i++) {
        let pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
    }
    return(false);
}
const Company:FC = () => {
    const {company}=useContext(Context);
    const classes = useStyles();
    document.title = "Компания"
    const [optionsList,setOptionsList]=useState([] as IOptions[])
    const [openPayment, setOpenPayment] = useState(false);
    const [openTariff, setOpenTariff] = useState(false);
    const { enqueueSnackbar } = useSnackbar();



    const openPaymentDialog = () => {
        setOpenPayment(true);
    }

    const getStatusPay = (status:string|undefined) => {
        if (status==undefined){
            return <Chip label={'Проверка'} />
        }
      switch (status){
          case 'Создан':
              return <Chip color="primary" label={'Создан'} />;
          case 'Оплачен':
              return <Chip style={{backgroundColor:'rgba(154,255,123,0.66)'}} label={'Оплачен'} />;
          default:
              return <Chip label={'Нет данных'} />;

      }
    }
    const createPayment = (cost:number) => {
      setOpenPayment(false);
      company.createOrder(cost).then(data=>{}).catch(e=>{
          alert(e.message)
      })
    }

    const handleClosePaymentDialog = () => {
        setOpenPayment(false);
    };
    const cardPay = (cost:number) => {
        company.createPayment(cost).then(()=>{
        })
    }


    useEffect(()=>{

        company.loadMyCompanyData().then((data)=>{
            setOptionsList([
                {name:'Название',option:company.companyData.name??''},
                {name:'ИНН',option:company.companyData.inn??''},
                {name:'Сотрудников',option:`${company.companyData.users!.length}`},
            ]);
        }).catch(e=>{
            alert(e.message)

        })
    },[])

    const changeTariff = (tariff:TariffDto) => {

      company.changeTariff(tariff.key).then(v=>{
          setOpenTariff(false)
      }).catch(e=>{
          setOpenTariff(false)
          alert(e.toString())
      })
    }
    const print_invoice= async ()=>{

    }
    return (
        <Main>
            <Header
                buttons={''}
                isLoading={company.isLoading}
                namePage={'Компания'}
            />
            {
                (!company.isLoading)?
                    <div className="container">
                        <div style={{ width: '100%'}}>
                            <Box display="flex" >
                                <Box p={1} flexGrow={1}>
                                    <div>
                                        <Typography variant="subtitle1" gutterBottom >
                                            Баланс
                                        </Typography>
                                        <Typography variant="h3" gutterBottom>
                                            {company.companyData.balance} ₽
                                        </Typography>


                                    </div>
                                </Box>
                                <Box p={1} margin={'auto'}>
                                    <Button color="primary" onClick={openPaymentDialog}>Пополнить баланс</Button>
                                </Box>
                            </Box>

                        </div>
                        <Tooltip
                            title={(company.companyData.tariff==1)?'Вы платите ежемесячно 1 000 рублей за каждого пользователя.':'Вы платите ежемесячно 8 000 рублей. Число пользователей не ограничено'} arrow>
                            <div style={{width:'20%'}}>
                                <Typography variant="button" display="block"  gutterBottom>
                                    Тариф: {(company.companyData.tariff==1)?'Старт':'Про'} <Button color="primary" onClick={()=>setOpenTariff(true)}>Изменить</Button>
                                </Typography>
                            </div>
                        </Tooltip>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    <Typography variant={'h5'}>
                                        О компании
                                    </Typography>
                                    {
                                        optionsList.map((item,index)=>
                                            <Box display="flex" key={index}>
                                                <Box pt={1} flexGrow={1}>
                                                    <div>
                                                        <Typography variant={'body1'}>
                                                            {item.name}:
                                                        </Typography>
                                                    </div>
                                                </Box>
                                                <Box p={1} margin={'auto'}>
                                                    <Typography variant={'body1'}>
                                                        {item.option}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    <Typography variant={'h5'}>
                                        Счета и платежи
                                    </Typography>
                                    {
                                        (company.companyData.orders!=undefined)&&company.companyData.orders.map((item)=>{
                                            return(
                                                <Box display="flex" key={item.id} className={classes.card} onClick={()=>print_invoice()}>
                                                    <Box pt={1} flexGrow={1}>
                                                        <div>
                                                            <Typography variant={'h6'}>
                                                                Счет номер {item.id}
                                                            </Typography>
                                                            <Typography variant={'subtitle1'}>
                                                                от {format((item.createdAt!==undefined)?new Date(item.createdAt):new Date(), 'dd.MM.yyyy')} на сумму {item.cost} руб.
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                    <Box p={1} margin={'auto'}>
                                                        <Typography variant={'body1'}>
                                                            {getStatusPay(item.status)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                    }
                                </Paper>
                            </Grid>
                        </Grid>
                        <PaymentDialog
                            handleClose={handleClosePaymentDialog}
                            show={openPayment}
                            createPayment={createPayment}
                            createPaymentCard={cardPay}/>
                        <SelectTariff
                         handleClose={()=>setOpenTariff(false)}
                         select={changeTariff}
                         show={openTariff}/>
                    </div>
                    :
                    <div>

                    </div>

            }


        </Main>
    );
};

export default observer(Company);