import React, {FC, useContext, useEffect} from 'react';
import {Button, Col, Modal, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";
import AutocompleteInput from "../widgets/AutocompleteInput";
import {Context} from "../../index";
import useInputMaterial from "../../hooks/useInputMaterial";
import useAutocomplete from "../../hooks/useAutocomplete";
import {useSnackbar} from "notistack";
import {IArshin} from "../../models/IArshin";
import CityAutocomplete from "../dadata/city_autocomplete";
import {SuggestionCity} from "../../models/dadata/city";

interface props{
    show:boolean;
    onHide:any;
    saveUser:any
    deleteUser:any
    worker:IArshin
}
const EditUserArshin:FC<props> = (props) => {
    const {usersArshin}=useContext(Context);
    const nameUser=useInputMaterial('');
    const cityUser=useAutocomplete('')
    const { enqueueSnackbar } = useSnackbar();


    useEffect(()=>{
        nameUser.setValue(props.worker.name??'')
        cityUser.setValue(props.worker.city??'')
    },[props.worker])
    const deleteUser=()=>{
        props.deleteUser(props.worker.id)
    }
    const create=()=>{
        if (cityUser.value==''){
            enqueueSnackbar('Укажите город',{variant:'error'})
            return;
        }
        const user={
            name:nameUser.value,
            city:cityUser.value,
            id:props.worker.id
        } as IArshin;
        usersArshin.saveUser(user).then(()=>{
            props.saveUser()
            nameUser.clear();
            cityUser.clear()
        }).catch((e)=>{
            enqueueSnackbar(e.toString(),{variant:'error'})
        })

    }
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            centered

        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Редактор пользователя аршина
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TextField label={'Имя пользователя'} fullWidth={true} className={"mt-3"} {...nameUser}  variant={"outlined"} />
                <CityAutocomplete setCity={(e:SuggestionCity)=> cityUser.setValue(e.data.city)}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-danger" className={"mr-auto"} onClick={deleteUser}>Удалить</Button>
                <Button variant="outline-danger" onClick={props.onHide}>Отмена</Button>
                <Button variant="outline-success" onClick={create}>Сохранить</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditUserArshin;