import React, {FC, useContext, useEffect, useState} from 'react';
import Main from "../components/Main";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Context, reports} from "../index";
import {observer} from "mobx-react-lite";
import {useSnackbar} from "notistack";
import PrintModel from "../components/modals/PrintModel";
import MUIDataTable, {MUIDataTableColumnDef, MUIDataTableOptions} from "mui-datatables";
import moment from "moment";
import {IReport} from "../models/IReport";
import EditReport from "../components/modals/EditReport";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Toolbar from "@material-ui/core/Toolbar";
import {lighten, makeStyles, Theme} from "@material-ui/core/styles";
import {Button, createStyles, Drawer, Paper} from "@material-ui/core";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const SPACED_DATE_FORMAT = "DD.MM.YYYY";


const columns:MUIDataTableColumnDef []= [
    {
        name: 'dateReport',
        label: 'Дата поверки',
        options:{
            customBodyRender: value =>
                moment(new Date(value)).format(SPACED_DATE_FORMAT),
            filter: true,
        }
        //    type: 'date',
        ///     minWidth:200,
        //     editable: false,
    },
    {
        name: 'nextReportData',
        label: 'Поверка действительна до',
        options:{
            customBodyRender: value =>
                moment(new Date(value)).format(SPACED_DATE_FORMAT),
            filter:false,

        },

        //     type: 'date',
        //     minWidth:200,
        //     editable: false,
    },
    {
        name: 'city',
        label: 'Город',
        options:{
            filterType:'multiselect'
        }

    },
    {
        name: 'userName',
        label: 'Ф.И.О. поверителя',
        //description: 'Реальный создатель отчета',
        //  minWidth:200,
        //  type:'string',
        //  editable: false,
    },
    {
        name: 'creatorName',
        label: 'Ф.И.О. в Аршин',
        //  type: 'string',
        //  minWidth:200,
        //    editable: false,

    },
    {
        name: 'fif',
        label: 'Номер в ФИФ',
        //  description: 'Номер ФИФ',
        //  type: 'string',
        //    minWidth:200,
        //    editable: false,
    },
    {
        name: 'modification',
        label: 'Модификация СИ',
        // type: 'string',
        //   minWidth:200,
        //   editable: false,
    },
    {
        name: 'factoryNumber',
        label: 'Номер счётчика',
        options:{
            filter:false
        }
        //  type: 'string',
        //   minWidth:200,
        //    editable: false,
    },
    {
        name: 'reference',
        label: 'Эталон',

        //  type: 'string',
        //  width: 160,
        //  editable: false,

    },
    {
        name: 'method',
        label: 'Методика поверки',
        //    type: 'string',
        //    width: 160,
        //    editable: false,
    },
    {
        name: 'temperature',
        label: 'Температура',
        options:{
            filter: false
        }
        //   sortable: false,
        //  width: 160,
    },
    {
        name: 'pressure',
        label: 'Давление',
        options:{
            filter: false
        }
        //   sortable: false,
        //   width: 160,

    },
    {
        name: 'humidity',
        label: 'Влажность',
        options:{
            filter: false
        }
        //   sortable: false,
        //   width: 160,
    },
];
const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
    }),
);
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

const Reports:FC = () => {
    const classesToolbar = useToolbarStyles();
    const classes = useStyles();


    const {reports}=useContext(Context);
    const [show,setShow]=useState(false)
    document.title = "Отчеты"
        //    const { enqueueSnackbar } = useSnackbar();
    const [filter,setFilters]=useState('[[],[],[],[],[],[],[],[],[],[]]');
    const [selectReport,setSelectReport]=useState({} as IReport)
    const [showEdit,setShowEdit]=useState(false);
    const options:MUIDataTableOptions = {
        filter: true,
        filterType: "dropdown",
        draggableColumns: {
            enabled: true
        },
        tableBodyHeight:'60vh',
        tableBodyMaxHeight:'60vh',
        onFilterChange:(changedColumn,filterList,type,changedColumnIndex,displayData)=>{
            const idArray=displayData.map(item=>{
                return reports.reports[item.dataIndex].id;
            });
           // alert(JSON.stringify(filterList))
            setFilters(JSON.stringify(filterList));
        },
        onSearchChange:(searchText)=>{

        },
        onRowClick:(rowData: string[], rowMeta: { dataIndex: number; rowIndex: number })=>{
            setSelectReport(reports.reports[rowMeta.rowIndex]);
            setShowEdit(true)
        },
        
        print:false,
        download:false,
        textLabels: {
            body: {
                noMatch:(reports.isLoading)?"Поиск":"Ничего не найдено",
                toolTip: "Поиск",
                columnHeaderTooltip: column => `Поиск по ${column.label}`
            },
            pagination: {
                next: "Следующая страница",
                previous: "Прошлая страница",
                rowsPerPage: "Строк на странице:",
                displayRows: "из",
            },
            toolbar: {
                search: "Поиск",
                downloadCsv: "Скачать отчет",
                print: "Print",
                viewColumns: "Столбцы",
                filterTable: "Фильтры",
            },
            filter: {
                all: "Все",
                title: "Фильтры",
                reset: "Сборсть фильтры",
            },
            viewColumns: {
                title: "Список столбцов",
                titleAria: "Скрыть/показать столбец",
            },
            selectedRows: {
                text: "Строк выбранно",
                delete: "Удалить",
                deleteAria: "Удалить выделенные строки",
            },
        },
        search:false,
        selectableRows:'none',

        /*
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
            return (
                <div style={{ marginTop: '40px' }}>
                    <Button onClick={() => applyNewFilters}>Применить фильтры</Button>
                </div>
            );
        },


        confirmFilters:true,
        onFilterConfirm:((filterList) => {
            alert(JSON.stringify(filterList))
        }),

         */



    };

    useEffect(()=>{
        reports.fetchReports().then().catch(e=>{
            alert(e.message);
        });
    },[])

    return (
        <Main>
            <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
                <div className="container-fluid">
                    <div className={'nav-sticky'}>
                        <div className="page-header-content">
                            <div className="row align-items-center justify-content-between pt-3">
                                <div className="col-auto mb-3">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i data-feather="file"></i></div>
                                        Отчеты
                                    </h1>
                                </div>
                                <div className="col-12 col-xl-auto mb-3">
                                    <Button onClick={()=>setShow(true)}  className="btn btn-sm btn-light text-primary active mr-2">Экспорт в Excel</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LinearProgress hidden={!reports.isLoading}/>

            </header>
            <div className={'container'}>
                <MUIDataTable
                    title={"Список отчетов"}
                    data={reports.reports}
                    columns={columns}
                    options={options}
                />
                <PrintModel show={show} onHide={()=>setShow(false)} data={filter}/>
                <EditReport show={showEdit} onHide={()=>setShowEdit(false)} report={selectReport}/>
            </div>
        </Main>
    );

};

export default observer(Reports);


/*

 */