import React, {FC, useContext, useEffect, useState} from 'react';
import {Button, Col,  Modal} from 'react-bootstrap';
import {IWorker, Reference} from "../../models/IWorkers";
import { Row } from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {useSnackbar} from "notistack";
import FormControl from '@material-ui/core/FormControl';
import InputMask from "react-input-mask";
import AutocompleteInput from "../widgets/AutocompleteInput";
import useAutocomplete from "../../hooks/useAutocomplete";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import {
    createStyles,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    TextField,
    Theme
} from "@material-ui/core";
import useInputMaterial from "../../hooks/useInputMaterial";
import {Autocomplete} from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CityAutocomplete from "../dadata/city_autocomplete";
import {SuggestionCity} from "../../models/dadata/city";


interface props{
    show:boolean;
    onHide:any;
    createUser:any
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);


const AddUser:FC<props> = (props) => {
    const classes = useStyles();
    const nameUser=useInputMaterial('');
    const [referenceType,setReferenceType]=useState('')
    const reference=useInputMaterial('');
    const passUser=useInputMaterial('');
    const [phone,setPhone]=useState('');

    const city=useInputMaterial('');




    const {store}=useContext(Context);
    const { enqueueSnackbar } = useSnackbar();
    const [referenceList,setReferenceList]=useState([] as Reference[]);



    useEffect(()=>{},[])
    const deleteRow=(name:Reference)=>{
        setReferenceList(referenceList.filter(item=>item!==name));
    }
    const addReference=()=>{
        if (reference.isEmpty()){
            return;
        }

        setReferenceList([...referenceList,{name:reference.value,type:referenceType}]);
        reference.clear()
        setReferenceType('')
    }
    const  create=async ()=>{
        let login=parseInt(phone.replace(/[^\d]/g, '')).toString().substring(1);
        const user={
            name:nameUser.value,
            cityName:city.value,
            phone:login,
            reference:referenceList
        } as IWorker;
        store.createUser(user).then((worker)=>{
            props.createUser(worker)
            setReferenceList([])
        }).catch((e)=>{
            enqueueSnackbar(e.toString(),{variant:'error'})
        })
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            centered
            size="xl"

        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Создание пользователя
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <TextField label={'Имя пользователя'} fullWidth={true} className={"mt-3"} {...nameUser}  variant={"outlined"} />
                        <InputMask
                            mask="+7 (999) 999-9999"
                            value={phone}
                            disabled={false}
                            onChange={e => setPhone(e.target.value)}
                        >
                            {() => <TextField variant={"outlined"} className={"mt-3"} fullWidth={true} label={'Телефон'}/>}
                        </InputMask>
                        <CityAutocomplete setCity={(e:SuggestionCity)=> city.setValue(e.data.city)}/>

                    </Col>
                    <Col>
                        <div>
                            <Row>
                                <Col>
                                    <TextField
                                        fullWidth={true}
                                        {...reference}
                                        label="Эталон"
                                        margin="normal"
                                        variant="outlined" />
                                </Col>
                                <Col>
                                    <Autocomplete
                                        freeSolo
                                        id="free-solo-2-demo"
                                        disableClearable
                                        options={['МИ 1592-2015','ГОСТ 8.156-83']}
                                        onChange={(event,newValue)=>{
                                            if (typeof newValue === 'string') {
                                                setReferenceType(newValue)
                                            }
                                        }}
                                        value={referenceType}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Метод"
                                                margin="normal"
                                                value={referenceType}
                                                onChange={e=>setReferenceType(e.target.value)}
                                                variant="outlined"
                                                InputProps={{ ...params.InputProps, type: 'search' }}
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>

                            <Button
                                variant={'outline-primary'}
                                className={'btn-block mt-3'}
                                onClick={addReference}
                            >Добавить</Button>
                            <List >
                                {
                                    referenceList.map(item=>{
                                        return (
                                            <ListItem>
                                                <ListItemText
                                                    primary={item.name}
                                                    secondary={item.type}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="delete">
                                                        <DeleteIcon onClick={()=>deleteRow(item)}/>
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                    <Button variant="outline-danger" onClick={props.onHide}>Отмена</Button>
                    <Button variant="outline-success" onClick={create}>Создать</Button>
                </Modal.Footer>
        </Modal>
    );
};

export default observer(AddUser);