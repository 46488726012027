import React, {FC} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import {TariffDto} from "../../models/dto/tariff.dto";

interface selectTariffAttrs{
    show:boolean;
    handleClose:any,
    select:(tariff:TariffDto)=>void
}
const tariffs=[
    {
        name:'Старт',
        description:'Вы платите за каждого пользователя 1 000 рублей ежемесячно',
        key:1
    } as TariffDto,
    {
        name:'Про',
        description:'Вы платите ежемесячно 8 000 руб. Число пользователей неограниченно',
        key:2
    }
] as TariffDto[];
const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const SelectTariff:FC<selectTariffAttrs> = (props) => {
    const classes = useStyles();

    return (
        <Dialog onClose={props.handleClose} aria-labelledby="simple-dialog-title" open={props.show}>
            <DialogTitle id="simple-dialog-title">Выберете тариф</DialogTitle>
            <List>
                {tariffs.map((item,index) => (
                    <ListItem button onClick={() => props.select(item)} key={index}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item.name} secondary={item.description}/>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
};

export default SelectTariff;