import {useState} from "react";

export default function usePhone(initialValue:string){
    const [value,setValue]=useState(initialValue);
    const [isDirty,setDirty]=useState(false);
    const [error,setError]=useState('');

    function onChange(e:any){
        let val = e.target.value;
        val = val.replace(/ /gm, '');
        console.log(val);
        let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;
        num = num.trim();
        setValue(num)
    }
    return {
        value,onChange,isDirty,error,setError
    }

}