import React, {FC, useState} from 'react';
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import Switch from '@material-ui/core/Switch';
import {createStyles, FormControlLabel, makeStyles, TextField, Theme} from "@material-ui/core";
import axios from "axios";
import {Convert, TableFilter} from "../../models/TableFilter";
import {GridFilterModel} from "@material-ui/data-grid";
import ReportService from "../../service/ReportService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
    }),
);

interface props{
    show:boolean;
    onHide:any;
    data:string
}
const PrintModel:FC<props> = (props) => {
    const classes = useStyles();
    const[start,setStart]=useState('')
    const [end,setEnd]=useState('')
    const [label,setLabel]=useState('Печать исправных')
    const [work,setWork]=useState(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWork(event.target.checked);
        if(event.target.checked){
            setLabel('Печать исправных')
        }else {
            setLabel('Печать не исправных')
        }
    };

    const print = async ()  => {
        let param='&';

        if (!start){
            alert('Укажите дату начала')
            return
        }
        if (!end){
            alert('Укажите дату окончания')
            return
        }
        try {
            alert(JSON.stringify(props.data))
            const res= await ReportService.printReport(props.data);
            const link = document.createElement('a');
            link.href = `https://mobile-arshin.ru/api/${res.data}`;
            link.setAttribute('download', 'file.xlsx');
            document.body.appendChild(link);
            link.click();
        }catch (e){
            alert(e.toString())
        }
    }
    const printXML = () => {
        let param='&';

        if (!start){
            alert('Укажите дату начала')
            return
        }
        if (!end){
            alert('Укажите дату окончания')
            return
        }
        console.log(`https://ekb-app.ru/vendor/hcs/createXML.php?start=${start}&end=${end}&error=${(work)?'0':'1'}&filter=${props.data}`);
        axios.post(
            `https://ekb-app.ru/vendor/hcs/createXML.php?start=${start}&end=${end}&error=${(work)?'0':'1'}&filter=${props.data}`,
            {item:''},
            {
                responseType:'blob',
                headers:{
                    "Access-Control-Allow-Origin": "*",
                },
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.xml');
                document.body.appendChild(link);
                link.click();
            }).catch(e=>{
                alert(e)
            });

    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            centered

        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Печать отчета
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <TextField
                            id="date"
                            label="С"
                            type="date"
                            onChange={e=>setStart(e.target.value)}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Col>
                    <Col>
                        <TextField
                            id="date"
                            label="По"
                            type="date"
                            onChange={e=>setEnd(e.target.value)}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Col>
                </Row>
                <FormControlLabel className={"mt-3"}
                    control={<Switch checked={work} onChange={handleChange} color={"primary"} />}
                    label={label}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-success" onClick={print}>Печать</Button>
                <Button variant="outline-success" onClick={printXML}>Файл XML</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PrintModel;