import {FC, useContext} from "react";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {authRoutes, publicRoutes} from "../routes";
import {LOGIN_ROUTE} from "../utils/consts";
import {Redirect, Route, Switch } from "react-router-dom";

const AppRouter: FC=()=>{
    const {store} = useContext(Context);
    console.log(store.isAuth)
    if (localStorage.getItem('token')) {
        store.setAuth(true)
    }

    return(
        <Switch>
            {store.isAuth&&authRoutes.map(({path, Component})=> <Route key={path} path={path} component={Component} exact/>)}
            {publicRoutes.map(({path, Component}) =>
                <Route key={path} path={path} component={Component} exact/>
            )}
            <Redirect to={LOGIN_ROUTE}/>
        </Switch>
    )
}
export default observer(AppRouter);