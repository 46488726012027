import React, {FC, useContext, useState} from 'react';
import {Context} from "../index";
import { useHistory } from 'react-router-dom';
import {observer} from "mobx-react-lite";
import useInput from "../hooks/useInput";
import {AuthService} from "../service/AuthService";
import usePhone from "../hooks/usePhone";
import InputMask from "react-input-mask";
import {COMPANY, USERS} from "../utils/consts";
import {Box, CircularProgress, Container, Grid, Paper, TextField} from "@material-ui/core";
import {TextInputMaterial} from "../components/widgets/TextInputMaterial";
import NavBar from "../components/NavBar";
import {SubmitHandler, useForm} from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import clsx from 'clsx';
import TextInput from "../components/widgets/TextInput";
import {Alert} from "@material-ui/lab";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));


type Inputs = {
    login: string,
    password: string,
};
const Login:FC = () => {
    const {store} = useContext(Context);
    const [error,setError]=useState('')
    const history=useHistory();
    document.title = "Вход"
    const classes = useStyles();

    const [success, setSuccess] = React.useState(false);

    async function onClick() {
        email.setError('');
        password.setError('');
        let login=parseInt(phone.replace(/[^\d]/g, '')).toString().substring(1);


        try{
            const response = await AuthService.login(login, password.value);
            console.log(JSON.stringify(response))
            localStorage.setItem('token', response.data.assetsToken);
            store.setAuth(true);
            store.setUser(response.data.user);
            history.push(USERS);
        }catch (e){
            alert(e)


        }

    }
    const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>({
        mode:'onBlur'
    });
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });
    const email=usePhone('');
    const [phone,setPhone]=useState('');
    const password=useInput('',{isEmpty:true});
    const onSubmit: SubmitHandler<Inputs> = async (data) =>{
        if (!store.isLoading) {
            let login=parseInt(phone.replace(/[^\d]/g, '')).toString().substring(1);
            store.login(login,data.password).then((res)=>{
                if (res){
                    setSuccess(true);
                    history.push(COMPANY)

                }else{
                    setSuccess(false);
                }

            }).catch(e=>{
                setError(e.toString())
            })

        }
    };
    const resetPassword=async ()=>{
        setError('')
        let login=parseInt(phone.replace(/[^\d]/g, '')).toString().substring(1);
        if(login.length==10){
            store.resetPassword(login).then(()=>{
                alert('Пароль отправлен в смс')
            }).catch(e=>{
                setError(e.toString());

            })
        }else{
            setError('Укажите номер')

        }
    }
    return (
        <div>
            <NavBar/>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '90vh' }}
            >
                <Grid container justify = "center" >
                    <Container maxWidth="sm" fixed >
                        <form  noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                            <Paper elevation={3} >
                                <Box p={3}>
                                    {
                                        (error!='')?<Alert severity="error">{error}</Alert>:''
                                    }
                                    <InputMask
                                        mask="+7 (999) 999-9999"
                                        value={phone}
                                        disabled={false}
                                        onChange={e => setPhone(e.target.value)}
                                    >
                                        {() => <TextField variant={"outlined"}  className={"mt-3"} fullWidth={true} label={'Телефон'}/>}
                                    </InputMask>
                                    <TextInputMaterial label={'Пароль'} {...register('password',{required:true})}/>

                                    <div className={classes.wrapper}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth={true}
                                            type={"submit"}
                                            className={buttonClassname}
                                        >
                                            Вход
                                        </Button>
                                        {store.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                    <Button
                                    fullWidth={true}
                                    onClick={resetPassword}
                                    >
                                        Сбросить пароль
                                    </Button>

                                </Box>
                            </Paper>
                        </form>
                    </Container>
                </Grid>
            </Grid>
        </div>
    );
};

export default observer(Login);
/*
<div>
            <NavBar/>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '90vh' }}
            >
                <Grid container justify = "center" >
                    <Container maxWidth="sm" fixed >
                        <form  noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                            <Paper elevation={3} >
                                <Box p={3}>
                                    <InputMask
                                        mask="+7 (999) 999-9999"
                                        value={phone}
                                        disabled={false}
                                        onChange={e => setPhone(e.target.value)}
                                    >
                                        {() => <TextField variant={"outlined"} className={"mt-3"} fullWidth={true} label={'Телефон'}/>}
                                    </InputMask>
                                    <TextInputMaterial label={'Пароль'} {...register('password',{required:true})}/>

                                    <div className={classes.wrapper}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth={true}
                                            type={"submit"}
                                            className={buttonClassname}
                                        >
                                            Вход
                                        </Button>
                                        {store.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>

                                </Box>
                            </Paper>
                        </form>

                    </Container>
                </Grid>
            </Grid>
        </div>
 */
/*
        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="card shadow-lg border-0 rounded-lg mt-5">
                                    <div className="card-header justify-content-center"><h3
                                        className="font-weight-light my-4">Вход</h3></div>
                                    <div className="card-body">
                                        <form>
                                            <InputMask
                                                value={phone}
                                                className={'form-control'}
                                                onChange={e => setPhone(e.target.value)}
                                                mask="+7\(999) 999-9999"
                                                aria-label={"Телефон"}
                                            />

                                            <TextInput label={'Пароль'} {...password}  textType={'password'} placeholder={"Укажите пароль"}/>
                                            <Button onClick={()=>onClick()}>Войти</Button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <div id="layoutAuthentication_footer">
                <footer className="footer mt-auto footer-dark">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 small">Copyright &copy; Your Website 2021</div>
                            <div className="col-md-6 text-md-right small">
                                <a href="#!">Privacy Policy</a>
                                &middot;
                                <a href="#!">Terms &amp; Conditions</a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>

 */