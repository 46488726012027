import React, {useContext, useEffect, useState} from 'react';
import {Context, usersArshin} from "../index";
import {useSnackbar} from "notistack";
import {IUser} from "../models/IUser";
import Main from "../components/Main";
import {Button} from "react-bootstrap";
import LinearProgress from "@material-ui/core/LinearProgress";
import AddUser from "../components/modals/AddUser";
import EditWorker from "../components/modals/EditWorker";
import {DataGrid, GridColDef} from "@material-ui/data-grid";
import {observer} from "mobx-react-lite";
import AddUserArshin from "../components/modals/addUserArshin";
import {IArshin} from "../models/IArshin";
import EditUserArshin from "../components/modals/EditUserArshin";
import Header from "../components/Header";

const columns :GridColDef[]= [
    {
        field: 'name',
        headerName: 'ФИО',
        type:'string',
        editable: false,
        flex: 1,

    },
    {
        field: 'city',
        headerName: 'Город',
        editable: false,
        flex: 0.3,

    },
];


const UsersArshin = () => {
    const {usersArshin}=useContext(Context);
    const [show,setShow]=useState(false);
    const [showEdit,setShowEdit]=useState(false);
    const [selectUser,setSelectUser]=useState({} as IArshin);
    const { enqueueSnackbar } = useSnackbar();
    const [usersList,setUsers]=useState([] as IArshin[]);
    const [filter,setFilter]=useState('[]')
    document.title = "Пользователи аршина"


    const createUser= async (user:IUser)=>{
        enqueueSnackbar('Пользователь создан',{variant:'success'})
        setUsers([...usersList,user])
        setShow(false)
    }
    const select=(user:IArshin)=>{
        setSelectUser(user);
        setShowEdit(true)
    }
    useEffect(()=>{

        usersArshin.fetchUsers().then((list)=>{
            setUsers(list.map(item=>{
                return {
                    ...item,
                    clickEvent:()=>{
                    }
                }
            }))
        }).catch((e)=>{
            enqueueSnackbar(e.message,{variant:'error'})
        })


    },[])

    const deleteUser=(id:number)=>{
        usersArshin.deleteUser(id).then(()=>{
            enqueueSnackbar('Пользователь Удален',{variant:'success'})
            setShowEdit(false)
        }).catch((e)=>{
            enqueueSnackbar(e.toString(),{variant:'error'})
        })
    }
    const saveUser = () => {
        enqueueSnackbar('Пользователь обновлен',{variant:'success'})
        setShowEdit(false)
    }
    return (
        <Main>
            <Header
                buttons={
                    <div>
                        <Button onClick={()=>{}}  className="btn btn-sm btn-light text-primary active mr-2">Экспорт в Excel</Button>
                        <Button onClick={()=>setShow(true)}  className="btn btn-sm btn-light text-primary active mr-2">Добавить сотрудника</Button>
                    </div>
                }
                isLoading={usersArshin.isLoading}
                namePage={'Аршин'}
            />
            <div className="container">
                <AddUserArshin show={show} onHide={()=>setShow(false)} createUser={createUser} />
                <EditUserArshin show={showEdit} onHide={()=>setShowEdit(false)} saveUser={saveUser} deleteUser={(id:number)=>{deleteUser(id)}} worker={selectUser}/>
                <div className="card">
                    <div className="card-body mt-3">
                        <DataGrid
                            rows={usersArshin.usersList.map(item=>item)}
                            columns={columns}
                            autoHeight
                            onCellClick={(param)=>{
                                setSelectUser(param.row as IArshin)
                                setShowEdit(true)
                            }
                            }
                            onFilterModelChange={model => {
                                setFilter(JSON.stringify(model))
                            }}
                            onSortModelChange={model => {
                                //alert(JSON.stringify(model))
                            }}
                        />

                    </div>
                </div>
            </div>
        </Main>
    );
};

export default observer(UsersArshin);