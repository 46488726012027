import axios from 'axios';


export const DADATA_URL='https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest';
const $dadata=axios.create({
    baseURL:DADATA_URL
})
$dadata.interceptors.request.use((config)=>{
    config.headers.Authorization = `Token b1844422df884c4b2e8a0cf9b19cf8b732b7999f`
    return config;
})

export default $dadata;